import React from "react";
import { getColor } from '../utils/ColorMapper'
import { MotionTable } from "./MotionTable";
import Tooltip from '@mui/material/Tooltip';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';


export class ClickTable extends MotionTable {
  constructor(props) {
    super(props);
    this.initFn = this.initFn.bind(this);
  }

  moveMouseEvent(id) {
    return (event) => {
      this.moveMouseInRow(id, this.getMouseX(event), this.getIndexFromY(this.getMouseY(event)));
    }
  }

  initFn(event) {
    this.initialMousePosition = this.getMouseX(event);
  }

  onTableClick(event) {
    this.dragCreate(event, false);
  }

  onTableDrag(event) {
    this.dragCreate(event, true)
  }

  tableActions() {
    return {onMouseDown: this.onRightMouseDown(this.initFn, this.onTableDrag, this.onTableClick, this.onTableClick), onMouseMove: this.onMouseMove, onMouseUp: this.onRightMouseUp };
  }

  slotActions(id) {
    return {onMouseDown: this.onRightMouseDown(this.initFn, this.moveMouseEvent(id), undefined, this.deleteMouseEvent(id))};
  }

  resizeActions(id) {
    return {onMouseDown: this.onRightMouseDown(this.initFn, this.expandMouseEventRight(id))};
  }

  render() {
    let eventFactory = (id, length, j, i, cumsum) => {
      let rangeText = this.props.tableCols[cumsum - length]["L"].content !== this.props.terminalCols[cumsum - 1]["L"].content ? `${this.props.tableCols[cumsum - length]["L"].content} - ${this.props.terminalCols[cumsum - 1]["L"].content}` : this.props.tableCols[cumsum - length]["L"].content;
      return <Tooltip key={j} arrow title={
        <div>
            <div>{this.props.tableRows[i]["L"].content}: {rangeText}</div>
        </div>
      }>
        <td className="selection" key={j} id={id} style={{background: getColor(this.props.color, 500)}} colSpan={length} {...this.slotActions(id)}>
          <div className="resizer" style={{background: getColor(this.props.color, 900)}} {...this.resizeActions(id)}>
            <DragIndicatorIcon style={{ color: 'white', fontSize: '10px'}} />
          </div>
        </td>
    </Tooltip>
    };
    return super.render(eventFactory);
  }
}