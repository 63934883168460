import React from "react"
import { Outlet, Navigate, useLocation } from "react-router-dom"
import { useAuth } from "./AuthContext"

export default function PrivateRoute() {
    const { currentUser } = useAuth();
    const location = useLocation();

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return currentUser ? <Outlet /> : <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} />;
}