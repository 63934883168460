import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';

const SelectChip = (props) => {
    if (props.value) {
        return <Chip sx={{mr: 1, mb: 1}} size="medium" label={props.optionKeys[props.optionValues.indexOf(props.value)]} onDelete={() => props.setValue(null)} />
    }
    return <FormControl sx={{mr: 1, mb: 1}} size='small' style={{minWidth: 150}}>
        <InputLabel id="type-select-label">{props.label}</InputLabel>
        <Select
            labelId="type-select-label"
            id="type-select"
            defaultValue={""}
            label={props.label}
            onChange={(event) => props.setValue(event.target.value)}
        >
            {props.optionKeys.map((optionKey, index) => (
                <MenuItem key={props.optionValues[index]} value={props.optionValues[index]}>{optionKey}</MenuItem>
            ))}
        </Select>
    </FormControl>
}

export default SelectChip;