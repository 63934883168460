import React from 'react';
import { useLocation } from 'react-router-dom'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Link from '@mui/material/Link';
import { capitalize } from '../utils/StringUtils';
import { CHAT_LINK, CHAT_NAME, DISTRIBUTION_SCHEDULER_NAME, EXTRA_LARGE_PLAN_NAME, FACE_LINK, FACE_NAME, FREE_PLAN_NAME, FULFILLMENT_SCHEDULER_NAME, INTERSECTION_SCHEDULER_NAME, LARGE_PLAN_NAME, LINKED_LINK, LINKED_NAME, MEDIUM_PLAN_NAME, PLANS_TITLE, PRIVACY_POLICY_LINK, PRIVACY_POLICY_TITLE, PRODUCTS_TITLE, SELECTION_SCHEDULER_NAME, SMALL_PLAN_NAME, SUBMIT_ISSUE_LINK, SUBMIT_ISSUE_NAME, SUPPORT_TITLE, TERMS_OF_USE_LINK, TERMS_OF_USE_TITLE, TUTORIALS_LINK, TUTORIALS_NAME, TWEET_LINK, TWEET_NAME, UNION_SCHEDULER_NAME, YOUTUBE_NAME } from '../utils/Constants';
import { log } from '../utils/AnalyticsUtils';

const titles = [PRODUCTS_TITLE, PLANS_TITLE, SUPPORT_TITLE]
const contents = [
    [`${UNION_SCHEDULER_NAME} scheduler`, `${INTERSECTION_SCHEDULER_NAME} scheduler`, `${SELECTION_SCHEDULER_NAME} scheduler`, `${DISTRIBUTION_SCHEDULER_NAME} scheduler`, `${FULFILLMENT_SCHEDULER_NAME} scheduler`], 
    [FREE_PLAN_NAME, SMALL_PLAN_NAME, MEDIUM_PLAN_NAME, LARGE_PLAN_NAME, EXTRA_LARGE_PLAN_NAME], 
    [TUTORIALS_NAME, SUBMIT_ISSUE_NAME, CHAT_NAME]
]
const links = [contents[0].map((content) => `/explore#${content.split(" ").join("_")}`),
    contents[1].map((content) => `/pricing?tier=${content}`),
    [TUTORIALS_LINK, SUBMIT_ISSUE_LINK, CHAT_LINK]
]

const media = [FACE_LINK, LINKED_LINK, TWEET_LINK, TUTORIALS_LINK];
const mediaNames = [FACE_NAME, LINKED_NAME, TWEET_NAME, YOUTUBE_NAME];
const mediaIcons = [FacebookIcon, LinkedInIcon, TwitterIcon, YouTubeIcon];

function Footer() {
    const location = useLocation();
    
    function isFilteredPath(path) {
        return (/^\/scheduler\//).test(path) || 
            path.includes('_scheduler') ||
            location.pathname === '/support/chat';
    }

    function logLinkOnClick(name) {
        return () => {
            log('link_click', {name: capitalize(name), metadata: "footer", origin: location.pathname});
        }
    }

    return isFilteredPath(location.pathname) || (
        <Container maxWidth="xl" sx={{mt: 24, overflow: "hidden"}}>
            <Divider light sx={{mb: 5}}/>
            <Stack sx={{display: "flex", mb: 5}} flexWrap="wrap" useFlexGap direction={{xs:'column', md:'row'}} spacing={{ xs: 1, sm: 2, md: 4 }}>
                <Box sx={{flexGrow: 0.25}} mb={2}>
                    <Typography variant="h5" sx={{fontWeight: 700, color: 'inherit', mb: 1}}>
                        Snug scheduler
                    </Typography>
                </Box>
                <Stack direction="row" alignItems="flex-start" justifyContent="space-around" gap={1}  sx={{flexGrow: 1}}>
                    {titles.map((title, index) => {
                        return <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component="div" variant="h7" mb={3}>
                                <b>{title}</b>
                            </Typography>
                            {contents[index].map((content, subIndex) => {
                                return <Link key={subIndex} onClick={logLinkOnClick(capitalize(content))} href={links[index][subIndex]} underline="hover" variant="subtitle1" color="text.secondary" mb={1}>
                                    {capitalize(content)}
                                </Link>
                            })}
                        </Box>
                    })}
                </Stack>
            </Stack>
            <Box sx={{width: '100%'}} mb={10}>
                <Divider light sx={{mb: 4}}/>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Stack direction="row" spacing={{xs: 1, sm: 2}}>
                        <Typography variant="body2" color="grey">
                            © Snug Scheduler
                        </Typography>
                        <Link onClick={logLinkOnClick("Terms of Use")} href={TERMS_OF_USE_LINK} underline="hover" variant="body2" color="grey">
                            {TERMS_OF_USE_TITLE}
                        </Link>
                        <Link onClick={logLinkOnClick("Privacy Policy")} href={PRIVACY_POLICY_LINK} underline="hover" variant="body2" color="grey">
                            {PRIVACY_POLICY_TITLE}
                        </Link>
                    </Stack>
           
                    <Stack direction="row" spacing={{xs: 1, sm: 2}}>
                        {media.map((link, index) => {
                            const Icon = mediaIcons[index];
                            return <Link key={index} onClick={logLinkOnClick(mediaNames[index])} href={link} aria-label={mediaNames[index]}>
                                <Icon style={{ color: 'grey' }}/>
                            </Link>
                        })}
                    </Stack>
                </Stack>
            </Box>
        </Container>
    );
}

export default Footer;
