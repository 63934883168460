import React, { useEffect } from 'react';
import { TERMS_OF_USE_TITLE } from '../utils/Constants';

export const TermsPage = () => {
    useEffect(() => {
        document.title = TERMS_OF_USE_TITLE;
    }, []);

    return (
        <div className="terms">
            <h1>Terms of Use for Snug Scheduler</h1>
            <p><strong>Effective Date: April 1, 2024</strong></p>

            <h2>1. Services Provided</h2>
            <p>Snug Scheduler is a service that arranges schedules based on availability data inputted by the user. We use custom algorithms to provide the best possible schedule arrangements given time and resource constraints.</p>

            <h2>2. User Responsibilities</h2>
            <p>Users are responsible for the following:</p>
            <ul>
            <li><strong>Providing hardware:</strong> When using the unpaid tier, users must provide the necessary hardware to calculate schedules.</li>
            <li><strong>Fair usage:</strong> Users must not use automated software to overwhelm the site with traffic or engage in non-human usage patterns.</li>
            <li><strong>Respecting intellectual property:</strong> Users must not attempt to decompile or reverse-engineer the source code, nor find and exploit security vulnerabilities on the site.</li>
            </ul>

            <h2>3. Intellectual Property Rights</h2>
            <p>Snug Scheduler owns the intellectual property rights to the custom algorithms used for schedule construction and the custom user input tools.</p>

            <h2>4. Content Ownership</h2>
            <p>Users retain ownership of the data and information they input into Snug Scheduler.</p>

            <h2>5. Privacy Policy</h2>
            <p>For information on how we collect, use, and protect your personal information, please refer to our <a href="/privacy_policy">Privacy Policy</a>.</p>

            <h2>6. Disclaimers</h2>
            <p>Please note the following disclaimers:</p>
            <ul>
            <li><strong>Schedule optimality:</strong> Schedules generated by Snug Scheduler may not be optimal due to time and resource constraints.</li>
            <li><strong>Liability:</strong> Snug Scheduler is not liable for any inaccuracies or inconveniences caused by the use of our services.</li>
            </ul>

            <h2>7. Limitation of Liability</h2>
            <p>Snug Scheduler limits its liability for any damages arising out of the use or inability to use our services. This includes, but is not limited to, liability for:</p>
            <ul>
            <li>Loss of data</li>
            <li>Interruption of service</li>
            <li>Cost of local computational resources</li>
            </ul>

            <h2>8. Contact Information</h2>
            <p>For support or inquiries, please contact us at
                <span> support</span>
                <span>@</span>
                <span>snugscheduler.com</span>
                .
            </p>
        </div>
    )
}
