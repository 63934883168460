import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PopUpDialog from './PopUpDialog';
import Box from '@mui/material/Box';
import { defaultValues } from '../../utils/DataUtils';

export default class AddSlotDialog extends PopUpDialog {
	constructor(props) {
		super(props);
	}

	handleChange(field) {
		return (event) => {
			let value = event.target.value;
			if (field === "length" || field === "prefTotal") {
				value = parseInt(value);
			}
			this.setState({[field]: value});
		}
	}

	handleClickOpen (inputState, passthroughVars) {
		inputState.content?.forEach((contentDict) => {
			this.setState({[contentDict.key]: contentDict.value});
		});
		this.passthroughVars = passthroughVars;
		super.handleClickOpen(inputState);
	}

	handleClose (success) {
		return () => {
			if (success) {
				let attributes = Object.fromEntries(this.state.inputState.content.map((contentMap) => [contentMap.key, this.state[contentMap.key]]));
				defaultValues(attributes, this.state.inputState.defaultAttributes);
				this.props.action({attributes: attributes, ...this.passthroughVars});
			}
			super.handleClose(success)();
		}
	}

	getDialogContent() {
		return <React.Fragment>
			<DialogTitle>Add a new slot</DialogTitle>
			<DialogContent>
				<Box display="flex" flexWrap="wrap" gap={1} m={1}>
					{this.state.inputState.content?.map((contentMap, index) => (
						<React.Fragment key={index}>
							{React.cloneElement(contentMap.component, {label: contentMap.key, value: this.state[contentMap.key], onChange: this.handleChange(contentMap.key), autoFocus: index === 0}) }
						</React.Fragment>
					))}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.handleClose(false)}>Cancel</Button>
				<Button onClick={this.handleClose(true)}>Add</Button>
			</DialogActions>
		</React.Fragment>
	}
}