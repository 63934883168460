import React, {useEffect} from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import { capitalize } from '../utils/StringUtils';
import { DISTRIBUTION_APPLICATIONS_ARRAY, DISTRIBUTION_DESCRIPTION, DISTRIBUTION_HOOK, DISTRIBUTION_SCHEDULER_NAME, 
	EXPLORE_TITLE, FULFILLMENT_APPLICATIONS_ARRAY, FULFILLMENT_DESCRIPTION, FULFILLMENT_HOOK, FULFILLMENT_SCHEDULER_NAME,
	INTERSECTION_APPICATIONS_ARRAY, INTERSECTION_DESCRIPTION, INTERSECTION_HOOK, INTERSECTION_SCHEDULER_NAME, 
	SELECTION_APPLICATIONS_ARRAY, SELECTION_DESCRIPTION, SELECTION_HOOK, SELECTION_SCHEDULER_NAME, 
	UNION_APPLICATIONS_ARRAY, UNION_DESCRIPTION, UNION_HOOK, UNION_SCHEDULER_NAME } from '../utils/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import {useAuth} from "./AuthContext";

const titles = [UNION_SCHEDULER_NAME, INTERSECTION_SCHEDULER_NAME, SELECTION_SCHEDULER_NAME, DISTRIBUTION_SCHEDULER_NAME, FULFILLMENT_SCHEDULER_NAME]//, "Multi-track separate", "Multi-track intersection", "Categorical"]
const pictures = ["separation.png", "intersection.png", "selection.png", "distribution.png", "fulfillment.png"];
const hooks = [UNION_HOOK, INTERSECTION_HOOK, SELECTION_HOOK, DISTRIBUTION_HOOK, FULFILLMENT_HOOK];
const applications = [UNION_APPLICATIONS_ARRAY, INTERSECTION_APPICATIONS_ARRAY, SELECTION_APPLICATIONS_ARRAY, DISTRIBUTION_APPLICATIONS_ARRAY, FULFILLMENT_APPLICATIONS_ARRAY];
const descriptions = [UNION_DESCRIPTION, INTERSECTION_DESCRIPTION, SELECTION_DESCRIPTION, DISTRIBUTION_DESCRIPTION, FULFILLMENT_DESCRIPTION];
 
const Explore = () => {
	const location = useLocation();
	const {currentUser} = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		document.title = EXPLORE_TITLE;
		if (currentUser) {
			navigate('/schedules');
		} else if (location.hash) {
			setTimeout(() => {
				document.getElementById(location.hash.slice(1))
					?.scrollIntoView({ behavior: 'smooth', });
			}, 100);
		}
	}, [currentUser, history]);

    return (
        <Container maxWidth="xl">
			<div>
				<h1>{EXPLORE_TITLE}</h1>
			</div>
			<Stack spacing={0} divider={<Divider flexItem />}>
				{titles.map((title, index) => {
					return <Card id={`${title.split(" ").join("_")}_scheduler`} key={index} elevation={0} >
						<CardActionArea onClick={() => window.open(`/${title.split(" ").join("_")}_scheduler`)}>
							<Box width="xl" display="flex" flexWrap="wrap" sx={{ justifyContent: 'center' }}>
								<Box display="flex" sx={{ justifyContent: 'center' }} pt={2} pb={2} pl={6} pr={6}>
									<CardMedia
										component="img"
										style={{flexGrow: 0.5, flexBasis: "200px"}}
										sx={{maxWidth: "250px", border: 1, p: 5, borderColor: 'grey.300', objectFit: "contain" }}
										image={`${process.env.PUBLIC_URL}/${pictures[index]}`}
										alt="Diagram"
									/>
								</Box>
								<CardContent style={{flexGrow: 1, flexBasis: "300px"}}>
									<Typography component="div" variant="h5">
										{capitalize(title)} scheduler
									</Typography>
									<Typography variant="subtitle1" color="text.secondary" component="div" mb={2}>
										{hooks[index]}
									</Typography>
									<Typography component="div" variant="body1" mb={1}>
										<b>Example:</b> {descriptions[index]}
									</Typography>
									<Typography component="div" variant="subtitle1" sx={{ fontStyle: 'italic'}}>
										{applications[index].join(", ")}
									</Typography>
								</CardContent>
						</Box>
					</CardActionArea>
				</Card>
			})}
        </Stack>
        </Container>
    );
};
 
export default Explore;