import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DOWNLOAD_DIALOG_CANCEL, DOWNLOAD_DIALOG_DESCRIPTION, DOWNLOAD_DIALOG_LABEL, DOWNLOAD_DIALOG_POSITIVE, DOWNLOAD_DIALOG_TITLE } from "../../utils/Constants";
import PopUpDialog from './PopUpDialog';

export default class DownloadDialog extends PopUpDialog {
    constructor(props) {
        super(props);
        this.state = {selectedIndex: 0};
    }

    handleClose (success) {
		return () => {
			if (success) {
				this.props.downloadOptions[this.state.selectedIndex].action();
			}
			super.handleClose(success)();
		}
	}

    handleSelect = (event) => {
        this.setState({selectedIndex: event.target.value});
    }

    getDialogContent() {
		return <React.Fragment>
			<DialogTitle>{DOWNLOAD_DIALOG_TITLE}</DialogTitle>
			<DialogContent>
				<DialogContentText>{DOWNLOAD_DIALOG_DESCRIPTION}</DialogContentText>
                <FormControl fullWidth margin='normal'>
                    <InputLabel id="download-options-label">{DOWNLOAD_DIALOG_LABEL}</InputLabel>
                    <Select
                        labelId="download-options-label"
                        value={this.state.selectedIndex}
                        onChange={this.handleSelect}
                        label={DOWNLOAD_DIALOG_LABEL}
                    >
                        {this.props.downloadOptions.map((option, index) => (
                            <MenuItem key={index} value={index}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.handleClose(false)}>{DOWNLOAD_DIALOG_CANCEL}</Button>
                <Button onClick={this.handleClose(true)}>{DOWNLOAD_DIALOG_POSITIVE}</Button>
			</DialogActions>
		</React.Fragment>
	}
}