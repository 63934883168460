import * as ComponentsUtil from "./ComponentsUtil";
import { getColors, getColor } from '../utils/ColorMapper'
import {defaultValues} from '../utils/DataUtils';


export function generateNclSlotContent(timeFormat) {
    let unit = timeFormat ? "minutes" : "days";
    return (attributes) => {
        defaultValues({...attributes}, {name: "Untitled Slot", length: 30, color: "blue"});
        let {name, length, color} = attributes;
        return {subtitle: "{length} " + unit, content: [
            {key: "name", value: name, component: ComponentsUtil.generateTextField(2.6, "20ch")},
            {key: "length", value: length, component: ComponentsUtil.generateNumberField(2, "14ch", 0, 1000, unit)},
            {key: "color", value: color, component: ComponentsUtil.generateColorDropDown(1, "6ch", getColors(), getColors().map(color => getColor(color, 500)))}
        ], attributes: attributes};
    }
}

export function generateNcSlotContent() {
    return (attributes) => {
        defaultValues({...attributes}, {name: "Untitled Slot", color: "blue"});
        let {name, color} = attributes;
        return {content: [
            {key: "name", value: name, component: ComponentsUtil.generateTextField(2.6, "20ch")},
            {key: "color", value: color, component: ComponentsUtil.generateColorDropDown(1, "6ch", getColors(), getColors().map(color => getColor(color, 500)))}
        ], attributes: attributes};
    }
}

export function generateNSlotContent() {
    return (attributes) => {
        defaultValues({...attributes}, {name: "Untitled Slot", color: "blue"});
        let {name, } = attributes;
        return {content: [
            {key: "name", value: name, component: ComponentsUtil.generateTextField(2.6, "20ch")},
        ], attributes: attributes};
    }
}

export function generateFulfillmentSlotContent(timeFormat) {
    let unit = timeFormat ? "minutes" : "days";
    return (attributes) => {
        defaultValues({...attributes}, {name: "Untitled Slot", color: "blue", prefTotal: timeFormat ? 30 : 5})
        let {name, color, prefTotal} = attributes;
        return {subtitle: "{prefTotal} " + unit, content: [
            {key: "name", value: name, component: ComponentsUtil.generateTextField(2.6, "20ch")},
            {key: "color", value: color, component: ComponentsUtil.generateColorDropDown(1, "6ch", getColors(), getColors().map(color => getColor(color, 500)))},
            {key: "prefTotal", value: prefTotal, component: ComponentsUtil.generateNumberField(2, "14ch", 0, 1000, unit)}
        ], attributes: attributes};
    }
}