import React, {useEffect} from 'react';
import { signInWithPopup, FacebookAuthProvider, TwitterAuthProvider, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import {auth} from "../firebase";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import {useNavigate, useSearchParams} from "react-router-dom";
import Stack from "@mui/material/Stack";
import {LOGIN_FAILED_MESSAGE, LOGIN_TITLE} from "../utils/Constants";
import {log, logLogin} from "../utils/AnalyticsUtils";
import CardActionArea from "@mui/material/CardActionArea";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';

const LoginPage = () => {
    let [searchParams] = useSearchParams();
    let navigate = useNavigate();
    let [snackbarOpen, setSnackbarOpen] = React.useState(false);
    let [snackbarMessage, setSnackbarMessage] = React.useState("");
    const providers = [
        {name: "Google", logo: "/login/google_logo.png", color: "#F2F2F2", textColor:"000000", provider: new GoogleAuthProvider()},
        {name: "Facebook", logo: "/login/facebook_logo.png", color: "#3b5998", provider: new FacebookAuthProvider()},
        {name: "Apple", logo: "/login/apple_logo.png", color: "#FFFFFF", textColor:"000000", border: 2, provider: new OAuthProvider('apple.com')},
        {name: "Microsoft", logo: "/login/microsoft_logo.png", color: "#2F2F2F", provider: new OAuthProvider('microsoft.com')},
        {name: "Yahoo", logo: "/login/yahoo_logo.png", color: "#410093", provider: new OAuthProvider('yahoo.com')},
        {name: "X", logo: "/login/x_logo.png", color: "#000000", provider: new TwitterAuthProvider()}
    ];

    function filter(redirect_url) {
        const validInternalLink = redirect_url.search(/^\/[^/]+(?:\/[^/]+)*$/) !== -1;
        if (validInternalLink) {
            return redirect_url;
        }
        return "/";
    }

    useEffect(() => {
        log("login_page_view", {metadata: searchParams.toString()});
        document.title = LOGIN_TITLE;
        if (auth.currentUser) {
            navigate("/");
        }
    }, []);


    const signIn = (provider) => {
        signInWithPopup(auth, provider)
        .then(() => {
            logLogin(provider.providerId);
            navigate(filter(searchParams.get("redirect")) || "/");
        }).catch((error) => {
            logLogin(`failure:${error?.customData?._tokenResponse?.providerId}`);
            setSnackbarMessage(LOGIN_FAILED_MESSAGE);
            setSnackbarOpen(true);
        });
    };

    const isSmallScreen = useMediaQuery('(max-width:350px)');
    return (
        <Container maxWidth="xl">
            <Stack mt={10} alignItems="center" spacing={2}>
                {providers.map((provider) => (
                    <Card key={provider.name} sx={{ width: "100%", maxWidth: 345, border: provider.border }}>
                        <CardActionArea onClick={() => signIn(provider.provider)}>
                            <Box backgroundColor={provider.color} sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                                <Avatar variant="square" src={provider.logo} alt={provider.name} sx={{
                                    ml: 2, mr: 2,
                                    objectFit: "scaleDown",
                                    width: '24px',
                                    height: '24px'
                                }}  />
                                <Typography color={provider.textColor || "white"} component="div" sx={{ ml: 3, fontSize: 16, fontWeight: 700}}>
                                    {isSmallScreen ? provider.name : `Sign in with ${provider.name}`}
                                </Typography>
                            </Box>
                        </CardActionArea>
                    </Card>
                ))}
            </Stack>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)}
                      message={snackbarMessage} />
        </Container>
    );
};

export default LoginPage;