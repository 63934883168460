import React from 'react';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Stack } from '@mui/material';
import { ANSWER_1, ANSWER_2, ANSWER_3, ANSWER_4, ANSWER_5, ANSWER_6, 
    CHAT_LINK, CHAT_NAME, FAQ_1, FAQ_2, FAQ_3, FAQ_4, FAQ_5, FAQ_6, 
    SUBMIT_ISSUE_LINK, SUBMIT_ISSUE_NAME, SUPPORT_TITLE, TUTORIALS_LINK, TUTORIALS_NAME } from '../utils/Constants';
import { log } from '../utils/AnalyticsUtils';

const options = [TUTORIALS_NAME, SUBMIT_ISSUE_NAME, CHAT_NAME]
const icons = [YouTubeIcon, FeedbackIcon, QuestionAnswerIcon]
const links = [TUTORIALS_LINK, SUBMIT_ISSUE_LINK, CHAT_LINK]

const faqs = [FAQ_1, FAQ_2, FAQ_3, FAQ_4, FAQ_5, FAQ_6]
const answers = [ANSWER_1, ANSWER_2, ANSWER_3, ANSWER_4, ANSWER_5, ANSWER_6]

export default class SupportPage extends React.Component {
    componentDidMount() {
        document.title = SUPPORT_TITLE;
    }
    
    render() {
        return <Container maxWidth="xl">
            <h1>{SUPPORT_TITLE}</h1>
            <Container maxWidth="lg">
                <Box display="flex" flexWrap="wrap" gap={1}>
                    {options.map((option, index) => {
                        let Icon = icons[index];
                        return <Card key={index} variant="outlined" sx={{ minWidth: 220, display: "flex", flexDirection: "column"}} style={{flexGrow: 1, flexBasis: "30ch"}} >
                            <CardActionArea onClick={() => log("button", {name: "Support - " + option})} sx={{p: 5, display: 'flex', justifyContent: 'center'}} href={links[index]}>
                                <Icon fontSize='large'/>
                                <Typography ml={2} noWrap variant="h6" sx={{fontWeight: 700}}>
                                    {option}
                                </Typography>
                            </CardActionArea>
                        </Card>
                    })}
                </Box>
            </Container>
            
            <Box p={3}>
                <Stack direction="row" spacing={1} alignItems="center" mt={2} mb={2}>
                    <ContactSupportIcon/>
                    <Typography variant="h5">FAQs</Typography>
                </Stack>
                {faqs.map((faq, index) => {
                    return <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>{faq}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{fontWeight: "bold"}}>
                                {answers[index]}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                })}
            </Box>
        </Container>
    }
}