import React from "react";
import Event from "./Event"
import { getColor } from '../utils/ColorMapper'
import { ActionTable } from "./ActionTable";
import MenuItem from '@mui/material/MenuItem';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import lodash from "lodash";
import { getCtrlSymbol, isMac } from "../utils/PlatformUtils";

export class MultiClickTable extends ActionTable {
	constructor(props) {
		super(props);
		this.count = 0;
		this.arr = this.getInternalArr(this.props.data, true);
		this.state.selectAllCount = 0;
		this.state.data = this.arr;
		this.state.history = [lodash.cloneDeep(this.arr)];
		this.state.historyIndex = 0;
		this.initFn = this.initFn.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}
	
	deleteMouseEvent(id) {
		return () => {
			let i = 0;
			while (i < this.arr.length) {
				let j = this.arr[i].findIndex(event => event.id === id)
				if (j === -1) {
					i += 1;
					continue;
				}
				if (this.arr[i][j].magnitude > 1) {
					this.arr[i][j].magnitude -= 1;
				} else {
					this.arr[i].splice(j, 1); //remove item
				}
				this.cleanRow(this.arr[i]);
				this.setState({data: this.arr});
				return;
			}
		}
	}

	cleanRow(eventArray) {
		for (let i = 0; i < eventArray.length - 1; i += 1) {
			if (eventArray[i].magnitude === eventArray[i + 1].magnitude && eventArray[i].end === eventArray[i + 1].start) {
				eventArray.splice(i, 2, new Event(eventArray[i].id, eventArray[i].start, eventArray[i + 1].end, eventArray[i].magnitude));
				i -= 1;
			}
		}
	}
	
	eventAdd(eventArray, event) {
		if (event.start < 0 || event.end > this.props.tableCols.length) {
			return false;
		}

		let curr = event.end;
		for (let i = eventArray.length - 1; i >= 0; curr = Math.min(curr, eventArray[i].start), i -= 1) {
			if (eventArray[i].start >= event.end) {
				continue;
			}
			if (eventArray[i].end <= event.start) {
				if (curr > event.start) {
					eventArray.splice(i + 1, 0, new Event(this.count++, event.start, curr, event.magnitude));
				}
				return true;
			}

			if (event.end > eventArray[i].end) {
				if (eventArray[i].end < curr) {
					eventArray.splice(i + 1, 0, new Event(this.count++, eventArray[i].end, curr, event.magnitude));
				}
			} else { //event.end <= eventArray[i].end
				let events = [new Event(this.count++, eventArray[i].start, event.end, eventArray[i].magnitude)];
				if (event.end < eventArray[i].end) {
					events.push(new Event(this.count++, event.end, eventArray[i].end, eventArray[i].magnitude));
				}
				eventArray.splice(i, 1, ...events);
			}

			if (event.start <= eventArray[i].start) {
				eventArray.splice(i, 1, new Event(this.count++, eventArray[i].start, eventArray[i].end, eventArray[i].magnitude + event.magnitude));
			} else { //event.start > eventArray[i].start
				let events = [new Event(this.count++, event.start, eventArray[i].end, eventArray[i].magnitude + event.magnitude)];
				if (eventArray[i].start < event.start) {
					events.unshift(new Event(this.count++, eventArray[i].start, event.start, eventArray[i].magnitude));
				}
				eventArray.splice(i, 1, ...events);
			}
		}

		if (event.start < curr) {
			eventArray.splice(0, 0, new Event(this.count++, event.start, curr, event.magnitude));
		}
		return true;
	}
	
	dragCreate(event, immutable) {
		let start = this.getIndexFromX(this.initialMousePosition);
		let end = this.getIndexFromX(this.getMouseX(event)) + 1;
		if (start > end) {
			[start, end] = [end, start];
		}
	
		if (start !== end) {
			let i = this.getIndexFromY(this.getMouseY(event));
			let table = this.arr;
			let count = immutable ? this.count : this.count++;
			if (immutable) {
			table = [...this.arr]
			table[i] =  [...table[i]];
			}

			this.eventAdd(table[i], new Event(count, start, end));
			this.cleanRow(table[i]);
			this.setState({data: table})
		} else {
			this.setState({data: this.arr})
		}
	}
	
	initFn(event) {
		this.initialMousePosition = this.getMouseX(event);
	}
	
	onTableClick(event) {
		this.dragCreate(event, false);
	}
	
	onTableDrag(event) {
		this.dragCreate(event, true)
	}
	
	tableActions() {
		return {onMouseDown: this.onRightMouseDown(this.initFn, this.onTableDrag, this.onTableClick, this.onTableClick), onMouseMove: this.onMouseMove, onMouseUp: this.onRightMouseUp };
	}
	
	slotActions(id) {
		return {onMouseDown: this.onRightMouseDown(this.initFn, this.onTableDrag, this.onTableClick, this.deleteMouseEvent(id))};
	}

	handleKeyDown = (event) => {
		super.handleKeyDown(event);
		const ctrlKey = isMac() ? event.metaKey : event.ctrlKey;

		if (ctrlKey && !event.shiftKey && (event.key === 'a' || event.key === 'A')) {
			event.preventDefault();
			event.stopPropagation();
			this.incrementSelectAll();
		} else if (ctrlKey && event.shiftKey && (event.key === 'a' || event.key === 'A')) {
			event.preventDefault();
			event.stopPropagation();
			this.decrementSelectAll();
		}
	}

	decrementSelectAll = () => {
		this.setState({selectAllCount: Math.max(this.state.selectAllCount - 1, 0)});
	}

	incrementSelectAll = () => {
		this.setState({selectAllCount: this.state.selectAllCount + 1});
	}

	additionalMenuItems() {
		let menuItems = [];
		menuItems.push(<MenuItem key="select" onClick={this.incrementSelectAll}>
			<ListItemIcon>
				{this.state.selectAllCount === 0 ? <SelectAllIcon fontSize="small" /> : <AddIcon fontSize="small" />}
			</ListItemIcon>
			<ListItemText>{this.state.selectAllCount === 0 ? "Select all" : "Increment" }</ListItemText>
			<Typography variant="body2" sx={{ color: 'text.secondary' }}>
				{getCtrlSymbol()}A
			</Typography>
		</MenuItem>)
		if (this.state.selectAllCount > 0) {
			menuItems.push(<MenuItem key="remove" onClick={this.decrementSelectAll}>
				<ListItemIcon>
					<RemoveIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Decrement</ListItemText>
				<Typography variant="body2" sx={{ color: 'text.secondary' }}>
					{getCtrlSymbol()}+Shift+A
				</Typography>
			</MenuItem>,
			<MenuItem key="deselect" onClick={() => this.setState({selectAllCount: 0})}>
				<ListItemIcon>
					<DeselectIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>Remove select all</ListItemText>
			</MenuItem>)
		}
		return menuItems;
	}

	render() {
	let table;
	if (this.state.selectAllCount > 0) {
		let convertedData = this.convertToTable([...Array(this.props.tableRows.length)].map(() => Array(0)));
		table = <table className="selection" ref={this.tableRef} width={"100%"}>
			{this.constructTableHeader()}
			<tbody>
				{convertedData.map((tableRow, i) => {
					return <tr className="selection" key={i} height={this.props.height}
						colSpan={this.props.tableCols.length + 1}>
						{this.constructTableRowHeader(i)}
						{tableRow.filter(([, length]) => length > 0).map(([, length], j) => {
							return <td className="selection" key={j} style={{background: getColor(this.props.color, 200)}}
								colSpan={length}>
								{this.state.selectAllCount}
							</td>
						})}
					</tr>
				})}
			</tbody>
		</table>
	} else {
		this.state.data.splice(this.props.tableRows.length, this.state.data.length - this.props.tableRows.length);
		for (let i = this.state.data.length; i < this.props.tableRows.length; i += 1) {
		this.state.data.push([]);
		}

		for (var i = 0; i < this.state.data.length; i += 1) {
		let j = this.state.data[i].length - 1;
		while (j >= 0) {
			if (this.state.data[i][j].end <= this.props.tableCols.length) {
				break;
			}
			j -= 1;
		}
		this.state.data[i].splice(j + 1, this.state.data[i].length - 1 - j);
		}
		let convertedData = this.convertToTable(this.state.data);
		table = <table className="selection" ref={this.tableRef} width={"100%"}
			{...this.tableActions()} >
			{this.constructTableHeader()}
			<tbody>
				{convertedData.map((tableRow, i) => {
					return <tr className="selection" key={i} height={this.props.height}
						colSpan={this.props.tableCols.length + 1}>
						{this.constructTableRowHeader(i)}
						{tableRow.filter(([, length,]) => length > 0).map(([id, length, magnitude], j) => {
							if (id !== undefined) {
								return <td className="selection" key={j} id={id}
									style={{background: getColor(this.props.color, 100 * Math.min(5, magnitude))}}
									colSpan={length} {...this.slotActions(id)}>
									{magnitude}
								</td>
							} else {
								return <td className="selection" key={j} colSpan={length}></td>
							}
						})}
					</tr>
				})}
			</tbody>
		</table>
	}
	return super.render(table);
	}

	returnData = () => {
		let data = [];
		for (var i = 0; i < this.arr.length; i += 1) {
			let row = [];
			for (var j = 0; j < this.arr[i].length; j += 1) {
				row.push([this.arr[i][j].start, this.arr[i][j].end, this.arr[i][j].magnitude])
			}
			data.push(row)
		}
		return [data, this.state.selectAllCount, this.state.clipboard];
	}

	getInternalArr(data, retain) {
		if (data === undefined || !retain) {
			data = [...Array(this.props.tableRows.length)].map(() => []);
		}
		let arr = new Array(this.props.tableRows.length)
		for (let i = 0; i < arr.length; i += 1) {
			arr[i] = new Array(0);
			if (data[i] === undefined) {
				continue;
			}
			for (let j = 0; j < data[i].length; j += 1) {
				arr[i].push(new Event(this.count++, data[i][j][0], data[i][j][1], data[i][j][2]));
			}
		}
		return arr;
	}

	loadData = (data, selectAllCount, retain, clipboard, doNotResetCache) => {
		this.arr = this.getInternalArr(data, retain);
		if (!doNotResetCache) {
			this.setState({history: [lodash.cloneDeep(this.arr)], historyIndex: 0});
		} else {
			this.cacheData(this.arr);
		}
		this.setState({data: this.arr, selectAllCount: selectAllCount, clipboard: clipboard})
	}
}