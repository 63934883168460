import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';

export default class LoadingPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <LinearProgress key="linearProgress"/>
            <Box key="loadingBox" width="100%" height="100%" sx={{p: 5, boxSizing: "border-box", display: 'flex', justifyContent: "center", alignItems:"center"}}>
                <Icon key="loadingLogo" style={{opacity: 0.1}} color="disabled" sx={{width: "100%", maxWidth: 350, height: "auto",
                    animation: "spin 2s ease infinite",
                    "@keyframes spin": {
                        "0%": {
                            transform: "rotate(0deg)",
                        },
                        "25%": {
                            transform: "rotate(-180deg)",
                        },
                        "50%": {
                            transform: "rotate(-180deg)",
                        },
                        "75%": {
                            transform: "rotate(-360deg)",
                        },
                        "100%": {
                            transform: "rotate(-360deg)",
                        }
                    },
                }}>
                    <img alt="snug logo" src={"/logo_filled.svg"}/>
                </Icon>
            </Box>
        </React.Fragment>
    }
}