import { contains, overlaps } from "../utils/ScheduleUtils";

//timesMap: {0: [[a, b], [c, d]], 5: ... }
//intervalsMaps: [[ { 0: [[a, b], [c, d]], 5: ... },  { 0: [[a, b], [c, d]], 5: ... }, ...], [ { 0: [[a, b], [c, d]], 5: ... } ], ...]
export default function findSolution(timesMap, intervalsMaps) {
    let boolMatrices = []; // [[ [[]], [[]], ... ], [ [[]], [[]], ... ], ... ]
    for (let x = 0; x < intervalsMaps.length; x += 1) {
        let boolMatrix = [];
        for (let y = 0; y < intervalsMaps[x].length; y += 1) {
            let nullified = !contains(timesMap, intervalsMaps[x][y]);
            let boolVector = [];
            for (let i = 0; i < intervalsMaps.length; i += 1) {
                let row = [];
                for (let j = 0; j < intervalsMaps[i].length; j += 1) {
                    if (i === x && j === y) { 
                        row.push(!nullified);
                    } else if (i === x || nullified) {
                        row.push(false);
                    } else {
                        row.push(!overlaps(intervalsMaps[x][y], intervalsMaps[i][j]));
                    }
                }
                boolVector.push(row);
            }
            boolMatrix.push(boolVector);
        }
        boolMatrices.push(boolMatrix);
    }

    let map = {};
    let maxMap = {};
    findSolutionHelper(boolMatrices, 0, map, maxMap);
    let missingList = [];
    for (let i = 0; i < intervalsMaps.length; i += 1) {
        if (maxMap[i] === undefined) {
            missingList.push(i);
        }
    }
    return {result: maxMap, missing: missingList};
}

function findSolutionHelper(boolMatrices, n, map, maxMap) {
    if (n === boolMatrices.length) {
        return;
    }
    for (let i = 0; i < boolMatrices[n].length; i += 1) {
        let compatible = boolMatrices[n][i][n][i];
        for (let [key, value] of Object.entries(map)) {
            if (!boolMatrices[n][i][key][value]) {
                compatible = false;
                break;
            }
        }
        if (compatible) {
            map[n] = i;
            if (Object.keys(map).length > Object.keys(maxMap).length) {
                for(const maxSetKey in maxMap) {
                    delete maxMap[maxSetKey];
                }
                for (const setKey in map) {
                    maxMap[setKey] = map[setKey];
                }
            }
        }
        findSolutionHelper(boolMatrices, n + 1, map, maxMap);
        if (compatible) {
            delete map[n];
        }
    }
}
