import {
    fromTimeString,
    fromDateString,
    toTimeString,
    toDateString,
    toDayString,
    getField,
    addField,
    findDayGap,
    mod,
    fromDateStringWithHour,
    modDayIsBetween
} from "./DateTimeUtils";

export default function getTableRowsAndCols(time, startTime, endTime, startDate, endDate, interval, startDay, endDay, span) {
    let rows = [];
    let cols = [];
    let metadata = {};
    let terminalCols = [];
    metadata["startDate"] = fromDateString(startDate);
    if (time) {
        metadata["scheduleEndTime"] = endTime;
        metadata["scheduleStartTime"] = startTime;
        metadata["scheduleEndDay"] = endDay;
        metadata["scheduleStartDay"] = startDay;

        let currTime = fromTimeString(startTime);
        let prevTime = fromTimeString(startTime);
        prevTime.setHours(currTime.getHours() - 1);
        endTime = fromTimeString(endTime);
        while (currTime.getTime() <= endTime.getTime()) {
            cols.push({"S": {content: toTimeString(currTime, "S", prevTime), ...(currTime.getMinutes() !== 0 && {style: {color: "dimgray", fontWeight: "normal"}})}, "M": {content: toTimeString(currTime, "M", prevTime)}, "L": {content: toTimeString(currTime, "L", prevTime)}});
            prevTime = new Date(currTime.getTime());
            currTime.setMinutes(currTime.getMinutes() + interval);
        }
        metadata["scale"] = interval;

        if (toTimeString(currTime, "M") === toTimeString(fromTimeString(startTime), "M")) {
            metadata["wrap"] = true;
        } else {
            metadata["wrap"] = false;
        }

        startDate = fromDateStringWithHour(startDate, 6);
        endDate = fromDateStringWithHour(endDate, 18);
        while (startDate <= endDate) {
            if (modDayIsBetween(getField("day", startDate), startDay, endDay)) {
                rows.push({"S": {content: toDateString(startDate, "S")}, "M": {content: toDateString(startDate, "M")}, "L": {content: toDateString(startDate, "L")}})
            }
            addField(1, "day", startDate)
        }
        metadata["start"] = fromTimeString(startTime);
        metadata["interval"] = "minute";
        metadata["range"] = 1440;
        terminalCols = cols.slice(1);
        cols = cols.slice(0, -1);
    } else {
        metadata["scheduleEndDay"] = endDay;
        metadata["scheduleStartDay"] = startDay;

        startDate = fromDateStringWithHour(startDate, 6);
        endDate = fromDateStringWithHour(endDate, 18);
        let startDateDay = getField("day", startDate);
        let startGap = getStartDateOffset(startDay, endDay, startDateDay);
        addField(startGap, "day", startDate);
        metadata["start"] = new Date(startDate.getTime());
        let endDateDay = getField("day", endDate);
        let endGap = getEndDateOffset(startDay, endDay, endDateDay);
        addField(endGap, "day", endDate);
        
        let numDays = findDayGap(startDay, endDay);
        for (let _ = 0; _ < span; _ += 1) {
            let currDate = new Date(startDate.getTime());
            for (var i = 0; i <= numDays; i += 1) {
                cols.push({"S": {content: toDayString(currDate, "S")}, "M": {content: toDayString(currDate, "M")}, "L": {content: toDayString(currDate, "L")}});
                addField(1, "day", currDate);
            }
        }
        metadata["scale"] = 1;

        while (startDate <= endDate) {
            rows.push({"S": {content: toDateString(startDate, "S")}, "M": {content: toDateString(startDate, "M")}, "L": {content: toDateString(startDate, "L")}})
            addField(span, "week", startDate)
        }

        if (numDays === 0 || numDays === 6) {
            metadata["wrap"] = true;
        } else {
            metadata["wrap"] = false;
        }
        metadata["interval"] = "day";
        metadata["range"] = span * 7;
        terminalCols = cols;
    }
    metadata["span"] = cols.length;
    metadata["numRows"] = rows.length;
    return [rows, cols, metadata, terminalCols];
}


// [3, 6], 2 => 1, 3 => 0, 4 => -1, 5 => -2, 6 => -3, 0 -> 3, 1 => 2
// [4, 3], 2 => -5, 3 => -6, 4 => 0, 5 => -1, 6 => -2, 0 => -3, 1 => -4
// -2 -> -5, -1 -> -6, 0 => 0, 1 -> -1, 2 -> -2, 3 -> -3, 4 -> -4, 5 -> -5, 6 -> -6

export function getStartDateOffset(startDay, endDay, startDateDay) {
    let minimum = mod((startDay - endDay) - 1, 7) - 6;
    return mod((startDay - startDateDay) - minimum, 7) + minimum;
}

// [3, 6], 2 => -3, 3 => -4, 4 => 2, 5 => 1, 6 => 0, 0 -> -1, 1 => -2
// [4, 3], 2 => 1, 3 => 0, 4 => -1, 5 => 5, 6 => 4, 0 => 3, 1 => 2
// -1 -> -1, 0 -> 0, 1 -> -6, 2 -> -5, 3 -> -4, 4 -> -3

export function getEndDateOffset(startDay, endDay, endDateDay) {
    let minimum = mod(endDay - startDay, 7) - 6;
    return mod((endDay - endDateDay) - minimum, 7) + minimum;
}

export function getTimeDifference(timeString1, timeString2) {
    let time1 = fromTimeString(timeString1);
    let time2 = fromTimeString(timeString2);
    return (time2.getTime() - time1.getTime()) / 60000;
}

export function normalizeTimeData(returnData, interval) {
    let normedData = [];
    for (let i = 0; i < returnData.length; i += 1) {
        let newRow = [];
        let row = returnData[i];
        for (let j = 0; j < row.length; j += 1) {
            let rowCopy = [...row[j]];
            rowCopy[0] *= interval;
            rowCopy[1] *= interval;
            newRow.push(rowCopy);
        }
        normedData.push(newRow);
    }
    return normedData;
}

export function normalizeDayData(returnData, span, startDay, endDay) {
    let normedData = [];
    let gap = findDayGap(startDay, endDay) + 1;
    for (let i = 0; i < returnData.length; i += 1) {
        let newRows = Array.from({ length: span }, () => []);
        let row = returnData[i];
        let rowIndex = 0;
        let j = 0;
        while (j < newRows.length && rowIndex < row.length) {
            if (row[rowIndex][0] < gap * (j + 1)) {
                let rowCopy = [...row[rowIndex]];
                rowCopy[0] = Math.max(gap * j, rowCopy[0]) % gap;
                rowCopy[1] = ((Math.min(gap * (j + 1), rowCopy[1]) - 1) % gap) + 1;
                newRows[j].push(rowCopy);
                if (row[rowIndex][1] <= gap * (j + 1)) {
                    rowIndex += 1;
                } else {
                    j += 1;
                }
            } else {
                j += 1;
            }
        }
        normedData.push(...newRows);
    }
    return normedData;
}

export function denormalizeTimeData(normedData, interval, scheduleStartTime, startTime, endTime) {
    let denormedData = [];
    let offset = getTimeDifference(startTime, scheduleStartTime);
    let maxTime = getTimeDifference(startTime, endTime);
    for (var i = 0; i < normedData.length; i += 1) {
        let newRow = [];
        let row = normedData[i];
        for (var j = 0; j < row.length; j += 1) {
            let rowCopy = [...row[j]];
            rowCopy[0] = Math.max(0, Math.round((rowCopy[0] + offset) / interval));
            rowCopy[1] = Math.round(Math.min(maxTime, (rowCopy[1] + offset)) / interval);
            if (rowCopy[0] < rowCopy[1]) {
                newRow.push(rowCopy);
            }
        }
        denormedData.push(newRow);
    }
    return denormedData;
}

export function denormalizeDayData(normedData, span, scheduleStartDay, scheduleEndDay, startDay, endDay) {
    let denormedData = [];
    let offset = getStartDateOffset(scheduleStartDay, scheduleEndDay, startDay);
    let gap = findDayGap(startDay, endDay) + 1;
    
    let i = 0;
    while (i < normedData.length) {
        let row = [];
        for (let j = 0; j < span && i < normedData.length; j += 1) {
            for (let k = 0; k < normedData[i].length; k += 1) {
                let rowCopy = [...normedData[i][k]];
                rowCopy[0] = Math.max(0, offset + rowCopy[0]) + j * gap;
                rowCopy[1] = Math.min(gap, offset + rowCopy[1]) + j * gap;
                if (rowCopy[0] < rowCopy[1]) {
                    //if (row.length > 0 && rowCopy[0] === row[row.length - 1][1]) {
                    //    row[row.length - 1][1] = rowCopy[1];
                    //} else {
                        row.push(rowCopy);
                    //}
                }
            }
            i += 1;
        }
        denormedData.push(row);
    }
    return denormedData;
}