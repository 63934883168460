import React from 'react';

export default function EmptyTable() {
    return <table className="selection" width="100%" height="100%">
        <tbody>
            <tr>
                <td className="centerText">No schedule was found</td>
            </tr>
        </tbody>
    </table>
}