import { contains } from "../utils/ScheduleUtils";

//timesMap: [ { 0: [[a, b], [c, d]], 5: ... },  { 0: [[a, b], [c, d]], 5: ... }, ...]
//intervalsMaps: [ { 0: [[a, b], [c, d]], 5: ... },  { 0: [[a, b], [c, d]], 5: ... }, ...]
export default function findSolution(timesMap, intervalsMaps) {
    let boolMatrix = [];
    for (let i = 0; i < timesMap.length; i += 1) {
        let boolVector = [];
        for (let j = 0; j < intervalsMaps.length; j += 1) {
            boolVector.push(contains(intervalsMaps[j], timesMap[i]));
        }
        boolMatrix.push(boolVector);
    }

    let map = {};
    let maxMap = {};
    findSolutionHelper(boolMatrix, 0, map, maxMap);
    let missingList = [];
    for (let i = 0; i < timesMap.length; i += 1) {
        if (maxMap[i] === undefined) {
            missingList.push(i);
        }
    }
    return {result: maxMap, missing: missingList};
}

function findSolutionHelper(boolMatrix, n, map, maxMap) {
    if (n === boolMatrix.length) {
        return;
    }
    for (let i = 0; i < boolMatrix[n].length; i += 1) {
        let compatible =  !Object.values(map).includes(i) && boolMatrix[n][i];
        if (compatible) {
            map[n] = i;
            if (Object.keys(map).length > Object.keys(maxMap).length) {
                for(const maxSetKey in maxMap) {
                    delete maxMap[maxSetKey];
                }
                for (const setKey in map) {
                    maxMap[setKey] = map[setKey];
                }
            }
        }
        findSolutionHelper(boolMatrix, n + 1, map, maxMap);
        if (compatible) {
            delete map[n];
        }
    }
}