import React from "react";
import SlotListList from "./SlotListList";
import StaticSlotList from "./StaticSlotList";
import { generateNSlotContent } from "../utils/SlotContentFactory";
import Slot from "./Slot";
import { defaultSingleSlot } from "../initial_data";

export default class StaticSlotListList extends SlotListList {
    constructor(props) {
        super(props);
        this.Slot = <Slot />;
        this.count = 0;
        this.dataAttributes = {};
        let data = this.props.data || [];
        for (var i = 0; i < data.length; i += 1) {
            let key = this.count++;
            this.slotRefs[key] = React.createRef();
            this.dataAttributes[key] = data[i].attributes;
        }
        this.state.orderToSlotIndex = [...Array(data.length).keys()]
        if (this.state.orderToSlotIndex.length > 0) {
            this.state.activeSlotId = this.state.orderToSlotIndex[0];
        }
    }

    getAuxContentList(slotNumber) {
        if (this.slotListRefs[slotNumber] === undefined) {
            this.slotListRefs[slotNumber] = React.createRef();
        }
        let contentList = super.getAuxContentList(slotNumber);
        let color = this.dataAttributes[slotNumber].color || "blue";
        contentList.push(<StaticSlotList key={slotNumber} ref={this.slotListRefs[slotNumber]} data={defaultSingleSlot(color)} slotClickAction={this.childSlotClickAction(slotNumber)} 
            propChangeAction={this.props.propChangeAction} dataChangeAction={this.props.dataChangeAction} timeFormat={this.props.timeFormat}
            selected={this.props.selected && this.state.activeSlotId === slotNumber}  contentFactory={generateNSlotContent(this.props.timeFormat)}
            rotateColors={false}/>);
        return contentList;
    }

    addSlot(slotName) {
        super.addSlot(slotName, this.count++);
    }
}