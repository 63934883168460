import { logEvent } from "firebase/analytics";
import { analytics, functions } from '../firebase';
import { httpsCallable } from "firebase/functions";

export function log(eventName, eventValues) {
    if (analytics() === undefined) {
        return;
    }
    logEvent(analytics(), eventName, eventValues);
}

export function logLogin(method) {
    if (analytics() === undefined) {
        return;
    }
    logEvent(analytics(), 'login', {
        method: method
    });
}

export function report(name, error, info, context) {
    httpsCallable(functions, 'report')({"name": name, "error": error, "info": info, "context": context})
    .then(() => {
        //console.error(result.data);
    })
}