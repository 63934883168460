import React, {useEffect} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase"
import LoadingPage from './LoadingPage';

import { PLANS_TITLE } from '../utils/Constants';

function PlanPage() {
    useEffect(() => {
        document.title = PLANS_TITLE;
    });

    const navigate = useNavigate();
    const currentUser = useAuth();

    if (currentUser) {
        let functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
        functionRef({
            returnUrl: window.location.origin
        }).then((result) => {
            const data = result.data;
            window.location.assign(data.url);
        }).catch(() => {
            navigate("/pricing")
        });
        return <LoadingPage />;
    } else {
        return <Navigate to='/pricing' />;
    }
}

export default PlanPage;
