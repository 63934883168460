export default class Event {
    constructor(id, start, end = start + 1, magnitude=1) {
        this.id = id;
        if (typeof start !== "number") {
            throw new Error('Parameter is not a number!');
        }
        this.start = start;
        this.end = end;
        this.magnitude = magnitude;
    }

    isEqual(otherEvent) {
        if (!(otherEvent instanceof Event)) {
            throw new Error('Parameter is not an instance of Event!');
        }
        return this.start === otherEvent.start &&
               this.end === otherEvent.end &&
               this.magnitude === otherEvent.magnitude;
    }
}