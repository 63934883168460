import React from 'react';
import DynamicScheduleCard from '../../components/DynamicScheduleCard';
import DynamicSlotListList from '../../components/DynamicSlotListList';
import DynamicSchedulerPage from './DynamicSchedulerPage';
import { generateNcSlotContent } from '../../utils/SlotContentFactory';
import ShareIcon from '@mui/icons-material/Share';
import { capitalize } from '../../utils/StringUtils';
import {
    ERROR_SOLUTION_MESSAGE,
    INVALID_SLOT_DELETE_MESSAGE,
    MAX_SLOT_ADD_MESSAGE, NO_SOLUTION_MESSAGE,
    PARTIAL_SOLUTION_MESSAGE,
    SELECTION_SCHEDULER_NAME,
    SLOTS_PER_PLAN
} from '../../utils/Constants';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {DynamicTabbedMultiDisplayTable} from "../../components/DynamicTabbedMultiDisplayTable";
import {getColor} from "../../utils/ColorMapper";
import {process3dData, scaleData} from "../../utils/DataUtils";


class DynamicSelectionSchedulerPage extends DynamicSchedulerPage {
    static SOLVER_NAME = "selection";
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <DynamicScheduleCard key="scheduleCard" icon="/selection_icon.png"  {...this.scheduleProps()}/>,
            slotList: () => <DynamicSlotListList key="slotList" contentFactory={generateNcSlotContent(this.state.time)} {...this.slotProps()}/>,
            displayTable: () => <DynamicTabbedMultiDisplayTable key="displayTable" processRequest={this.processRequest}
                processResponse={this.processResponse} solverProps={{name: DynamicSelectionSchedulerPage.SOLVER_NAME, maxSolutions: 10}} {...this.displayProps()} />
        };
    }

    getActions(metadata) {
        let actions = super.getActions(metadata);
        actions[3] = { icon: <ShareIcon color="disabled"/>, name: 'Share', action: () => this.setState({snackbarMessage: "Sharing is not supported at this time.", snackbarOpen: true})}
        return actions;
    }

    dialogAction (action) {
        switch(action) {
            case "add":
                return (properties) => {
                    if (properties.childSlot) {
                        this.slotsRef.current.addChildSlot(properties.attributes, this.slotsRef.current.getChildDefaultAttributesContent());
                    } else {
                        this.slotsRef.current.addSlot(properties.attributes, this.slotsRef.current.getDefaultAttributesContent());
                    }
                }
            default:
                return super.dialogAction(action);
        }
    }

    openDialog (action, passthroughVars) {
        return () => {
            let limit = SLOTS_PER_PLAN[this.props.id][this.state.planName];
            switch(action) {
                case "add":
                    if (this.state.scheduleActive && this.slotsRef.current.getSize() >= limit ||
                        !this.state.scheduleActive && this.slotsRef.current.getSize() > 0 && this.slotsRef.current.getChildSize() >= limit) {
                        this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max ${limit})`});
                    } else {
                        if (!this.state.scheduleActive) {
                            let [content, attributes] = this.slotsRef.current.getChildDefaultAttributesContent();
                            this.addDialogRef.current.handleClickOpen({content: content, defaultAttributes: attributes}, {...passthroughVars, childSlot: true});
                        } else {
                            let [content, attributes] = this.slotsRef.current.getDefaultAttributesContent();
                            this.addDialogRef.current.handleClickOpen({content: content, defaultAttributes: attributes}, {...passthroughVars, childSlot: false});
                        }
                    }
                    break;
                case "delete":
                    if (this.state.scheduleActive || this.state.displayActive || this.slotsRef.current.getSize() === 0) {
                        this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                default:
                    super.openDialog(action, passthroughVars)();
            }
        }
    }

    async findSolution(timesMap, intervalsMaps, metadata) {
        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};
        let input = this.processRequest(timesMap, intervalsMaps, metadata);
        //console.log(timesMap, intervalsMaps);
        let response = await this.callSolver(DynamicSelectionSchedulerPage.SOLVER_NAME, input);
        //console.log("response", response);
        return this.processResponse(response, metadata);
    }

    processRequest(timesMap, intervalsMaps, ) {
        let slots = intervalsMaps.map((intervalsMapArray) => {
            return intervalsMapArray.map((intervalsMap) => {
                return {"rows": intervalsMap};
            });
        });
        return {"schedule":{"rows": timesMap}, "slots": slots};
    }

    processResponse(response, metadata) {
        if (response === undefined) {
            return [{messages: [{severity: "error", content: ERROR_SOLUTION_MESSAGE}]}];
        }
        let slotsInfo = metadata["slotsInfo"];
        let messages = [];
        let result = response.status?.result;
        if (result === "FAILURE") {
            messages.push({severity: "error", content: NO_SOLUTION_MESSAGE});
        } else if (result === "PARTIAL_SUCCESS") {
            messages.push({severity: "warning", content: `${PARTIAL_SOLUTION_MESSAGE} ${response.status.messageData?.failureIndices?.map((index) => slotsInfo["nameArray"][index]).join(", ")}`});
        }
        let solutionData = {};
        for (let rowIndex of Object.keys(response.schedule.table)) {
            solutionData[rowIndex] = response.schedule.table[rowIndex].map((entry) => {
                let index = entry.data.index;
                return {"interval": [entry.start, entry.end], "borderColor": getColor(slotsInfo["colorArray"][index], 800), "color": getColor(slotsInfo["colorArray"][index], 500), "label": [slotsInfo["nameArray"][index]]};
            });
        }
        return [{messages: messages, table: {solutionData: solutionData}}];
    }

    processSlotListData(slotListDatas, slotListStartDaysList, slotListEndDaysList, slotListStartTimesList, slotListEndTimesList, slotListSelectAlls, metadata) {
        return process3dData(slotListDatas, slotListStartDaysList, slotListEndDaysList, slotListStartTimesList, slotListEndTimesList, slotListSelectAlls, this.state.time, metadata, this.denormalizeData, scaleData(metadata["scale"]));
    }

    componentDidMount() {
        super.componentDidMount();
        document.title = `${capitalize(SELECTION_SCHEDULER_NAME)} scheduler | Snug Scheduler`;
    }
}

export default withErrorBoundary(DynamicSelectionSchedulerPage);