import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PopUpDialog from './PopUpDialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import LinkIcon from '@mui/icons-material/Link';
import Snackbar from '@mui/material/Snackbar';
import { SHARE_SLOT_DESCRIPTION, SHARE_SLOT_TITLE } from '../../utils/Constants';
import { log } from '../../utils/AnalyticsUtils';

export default class ShareDialog extends PopUpDialog {
	handleClickOpen (inputState) {
		this.setState({enabled: inputState.enabled})
		super.handleClickOpen(inputState);
	}

	handleSnackbarOpen = () => {
		log("button", {name: "Copy Link"})
		this.setState({snackbarOpen: true});
		navigator.clipboard.writeText(`https://snugscheduler.com/scheduler/${this.state.inputState.scheduleId}/share?key=${this.state.inputState.key}`);
	}

	getDialogContent() {
		return <React.Fragment>
			<DialogTitle>{SHARE_SLOT_TITLE}</DialogTitle>
			<DialogContent>
				<DialogContentText>{SHARE_SLOT_DESCRIPTION}</DialogContentText>
				<FormControlLabel
					sx={{ mt: 1 }}
					control={
						<Switch checked={this.state.enabled} onChange={() => {
							this.props.action(!this.state.enabled);
							this.setState({enabled: !this.state.enabled});
						}}/>
					}
					label="Enable sharing"
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" startIcon={<LinkIcon />} onClick={this.handleSnackbarOpen}>
					Copy Link
				</Button>
				<Snackbar
				open={this.state.snackbarOpen}
				autoHideDuration={3000}
				onClose={() => this.setState({snackbarOpen: false})}
				message="Copied to clipboard"
				/>
				<Button variant="contained" onClick={this.handleClose(true)}>Done</Button>
			</DialogActions>
		</React.Fragment>
	}
}