//Returns true if set 2 is contained by set 1
export function contains(set1, set2) {
    for (let val in set2) {
        if (set1[val] === undefined) {
            return false;
        }
        let schedule1 = set1[val];
        let schedule2 = set2[val];
        let index1 = 0;
        let index2 = 0;
        while (index1 < schedule1.length && index2 < schedule2.length) {
            if (schedule2[index2][0] < schedule1[index1][0]) {
                return false;
            }

            if (schedule2[index2][1] > schedule1[index1][1]) {
                index1 += 1;
            } else if (schedule2[index2][1] < schedule1[index1][1]) {
                index2 += 1;
            } else {
                index1 += 1;
                index2 += 1;
            }
        }

        if (index2 < schedule2.length) {
            return false;
        }
    }
    return true;
}

export function overlaps(set1, set2) {
    for (let val in set1) {
        if (set2[val] !== undefined) {
            let schedule1 = set1[val];
            let schedule2 = set2[val];
            let index1 = 0;
            let index2 = 0;
            while (index1 < schedule1.length && index2 < schedule2.length) {
                if (schedule1[index1][0] < schedule2[index2][0]) {
                    if (schedule1[index1][1] > schedule2[index2][0]) {
                        return true;
                    }
                    index1 += 1;
                } else if (schedule1[index1][0] > schedule2[index2][0]) {
                    if (schedule2[index2][1] > schedule1[index1][0]) {
                        return true;
                    }
                    index2 += 1;
                } else { // schedule1[index1][0] == schedule2[index2][0]
                    return true;
                }
            }
        }
    }
    return false;
}