import React from 'react';
import Dialog from '@mui/material/Dialog';

export default class PopUpDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {open: false, inputState: {}};
	}

	handleClickOpen(inputState) {
		this.setState({open: true, inputState: inputState || {}});
	}

	handleClose() {
		return () => {
			this.setState({open: false});
		}
	}

	handleEnter = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.handleClose(true)();
		}
	}

	getDialogContent() {
		return undefined;
	}

	render() {
		return  <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="xs" onKeyDown={this.handleEnter}>
			{this.getDialogContent()}
		</Dialog>
	}
}