//timesMap: {0: [[a, b, 1], [c, d, 4]], 5: ... }
//intervalsMaps: [ { 0: [[a, b], [c, d]], 5: ... } ]
export default function findSolution(timesMap, intervalsMaps, prefTotals) {
    let overlapMap = {};
    for (let i = 0; i < intervalsMaps.length; i += 1) {
        let intervalsMap = intervalsMaps[i];
        for (let key in intervalsMap) {
            let row = intervalsMap[key];
            if (!(key in overlapMap)) {
                overlapMap[key] = [];
            }
            for (let j = 0; j < row.length; j += 1) {
                let interval = row[j];
                for (let val = interval[0]; val < interval[1]; val += 1) {
                    if (overlapMap[key][val] === undefined) {
                        overlapMap[key][val] = new Set();
                    }
                    overlapMap[key][val].add(i);
                }
            }
        }
    }

    let filteredOverlapMap = {};
    for (let key in timesMap) {
        if (overlapMap[key] === undefined) {
            continue;
        }
        let row = timesMap[key];
        for (let i = 0; i < row.length; i += 1) {
            let interval = row[i];
            for (let j = interval[0]; j < interval[1]; j += 1) {
                if (overlapMap[key][j] !== undefined) {
                    if (filteredOverlapMap[key] === undefined) {
                        filteredOverlapMap[key] = [];
                    }
                    filteredOverlapMap[key][j] = {set: overlapMap[key][j], magnitude: interval[2]};
                }
            }
        }
    }

    let si = [];
    for (let key in filteredOverlapMap) {
        let row = filteredOverlapMap[key];
        let startIndex = undefined;
        let previousIndex = undefined;
        let previousSet = undefined;
        let previousMagnitude = undefined;
        let total = 0;
        let maxSize = 0;
        for (let index = 0; index < row.length; index += 1) {
            let entry = row[index];
            if (entry === undefined || index !== previousIndex + 1 || (previousSet !== undefined && !setsAreEqual(entry.set, previousSet)) || previousMagnitude !== entry.magnitude) {
                if (startIndex !== undefined) {
                    si.push({total: total, maxSize: maxSize,interval: [startIndex, previousIndex + 1], set: previousSet, key: key});
                }
                startIndex = entry !== undefined ? index : undefined;
                total = 0;
                maxSize = 0;
            }
            if (entry !== undefined) {
                previousIndex = index;
                previousSet = entry.set;
                previousMagnitude = entry.magnitude;
                total += entry.magnitude;
                maxSize += 1;
            }
        }
        if (previousSet !== undefined && startIndex !== undefined) {
            si.push({total: total, maxSize: maxSize, interval: [startIndex, previousIndex + 1], set: previousSet, key: key});
        }
    }

    let so = [...prefTotals]
    let forward = [];
    for (let i = 0; i < so.length; i += 1) {
        let row = [];
        forward.push(row);
        for (let j = 0; j < si.length; j += 1) {
            if (si[j].set.has(i)) {
                row.push(si[j].maxSize);
            } else {
                row.push(0);
            }
        }
    }

    let backward = [];
    for (let j = 0; j < si.length; j += 1) {
        backward.push(new Array(so.length).fill(0));
    }

    

    let returnSchedules = [];
    for (let i = 0; i < so.length; i += 1) {
        let returnSchedule = {};
        for (let j = 0; j < backward.length; j += 1) {
            if (!(si[j].key in returnSchedule)) {
                returnSchedule[si[j].key] = [];
            }
            returnSchedule[si[j].key].push(si[j].interval);
        }
        returnSchedules.push(returnSchedule);
    }

    optimize(so, forward, backward, si);

    let backwardIntervals = [];
    for (let i = 0; i < backward.length; i += 1) {
        let backwardIntervalsRow = new Array(backward[i].length);
        let maxSize = si[i].maxSize;
        let index = 0;
        for (let j = backward[i].length - 1; j >= 0; j -= 1) {
            let intervals = [];
            if (backward[i][j] > 0) {
                intervals.push([index, Math.min(index + backward[i][j], maxSize)])
                if (index + backward[i][j] > maxSize) {
                    intervals.unshift([0, (index + backward[i][j]) % maxSize])
                }
                index = (index + backward[i][j]) % maxSize;
            }
            backwardIntervalsRow[j] = intervals;
        }
        backwardIntervals.push(backwardIntervalsRow);
    }

    let solutionSchedules = [];
    for (let i = 0; i < so.length; i += 1) {
        let solutionSchedule = {};
        for (let j = 0; j < backwardIntervals.length; j += 1) {
            if (!(si[j].key in solutionSchedule)) {
                solutionSchedule[si[j].key] = [];
            }
            for (let interval of backwardIntervals[j][i]) {
                let newInterval = [interval[0] + si[j].interval[0], interval[1] + si[j].interval[0]];
                if (solutionSchedule[si[j].key].length > 0 && solutionSchedule[si[j].key][solutionSchedule[si[j].key].length - 1][1] === newInterval[0]) {
                    solutionSchedule[si[j].key][solutionSchedule[si[j].key].length - 1][1] = newInterval[1];
                } else {
                    solutionSchedule[si[j].key].push(newInterval);
                }
            }
        }
        solutionSchedules.push(solutionSchedule);
    }

    let solutionOverlapMap = {};
    for (let i = 0; i < solutionSchedules.length; i += 1) {
        let intervalsMap = solutionSchedules[i];
        for (let key in intervalsMap) {
            let row = intervalsMap[key];
            if (!(key in solutionOverlapMap)) {
                solutionOverlapMap[key] = [];
            }
            for (let j = 0; j < row.length; j += 1) {
                let interval = row[j];
                for (let val = interval[0]; val < interval[1]; val += 1) {
                    if (solutionOverlapMap[key][val] === undefined) {
                        solutionOverlapMap[key][val] = {set: new Set(), capacity: filteredOverlapMap[key][val].magnitude};
                    }
                    solutionOverlapMap[key][val].set.add(i);
                }
            }
        }
    }

    for (let key in timesMap) {
        if (solutionOverlapMap[key] === undefined) {
            solutionOverlapMap[key] = [];
        }
        let row = timesMap[key];
        for (let i = 0; i < row.length; i += 1) {
            let interval = row[i];
            for (let j = interval[0]; j < interval[1]; j += 1) {
                if (!solutionOverlapMap[key][j]) {
                    solutionOverlapMap[key][j] = {set: new Set(), capacity: interval[2]};
                }
            }
        }
    }

    let compressedSolutionOverlapMap = {};
    for (let key in solutionOverlapMap) {
        let row = solutionOverlapMap[key];
        compressedSolutionOverlapMap[key] = [];
        let startIndex = undefined;
        let previousIndex = undefined;
        let previousMagnitude = undefined;
        let previousCapacity = undefined;
        for (let index = 0; index < row.length; index += 1) {
            let entry = row[index];
            if (entry === undefined || index !== previousIndex + 1 || (previousMagnitude !== undefined && previousMagnitude !== entry.set.size) || (previousCapacity !== undefined && previousCapacity !== entry.capacity)) {
                if (startIndex !== undefined) {
                    compressedSolutionOverlapMap[key].push({interval: [startIndex, previousIndex + 1], magnitude: previousMagnitude, capacity: previousCapacity});
                }
                startIndex = entry !== undefined ? index : undefined; 
            }
            if (entry !== undefined) {
                previousIndex = index;
                previousMagnitude = entry.set.size;
                previousCapacity = entry.capacity;
            }
        }
        if (previousMagnitude !== undefined && startIndex !== undefined && previousCapacity !== undefined) {
            compressedSolutionOverlapMap[key].push({interval: [startIndex, previousIndex + 1], magnitude: previousMagnitude, capacity: previousCapacity});
        }
    }
    let solutionSchedulesMap = {};
    let missingIndices = [];
    for (let i = 0; i < solutionSchedules.length; i += 1) {
        if (Object.keys(solutionSchedules[i]).length === 0) {
            missingIndices.push(i);
        } else {
            solutionSchedulesMap[i] = solutionSchedules[i];
        }
    }

    return {density: compressedSolutionOverlapMap, schedules: solutionSchedulesMap, missing: missingIndices}
}

function optimize(so, forward, backward, si) {
    let found = true;
    while (found) {
        found = false;
        for (let i = so.length - 1; i >= 0; i -= 1) {
            if (so[i] > 0) {
                let minimum = [so[i]];
                let visitedSo = new Array(so.length).fill(false);
                let visitedSi = new Array(si.length).fill(false);
                if (search(so, forward, backward, si, i, visitedSo, visitedSi, true, minimum)) {
                    so[i] -= minimum[0];
                    found = true;
                    break;
                }
            }
        }
    }
}

function search(so, forward, backward, si, index, visitedSo, visitedSi, soMode, minimum) {
    if (soMode) {
        visitedSo[index] = true;
        for (let i = si.length - 1; i >= 0; i -= 1) {
            if (!visitedSi[i] && forward[index][i] > 0) {
                let oldMinimum = minimum[0];
                minimum[0] = Math.min(oldMinimum, forward[index][i]);
                if (search(so, forward, backward, si, i, visitedSo, visitedSi, false, minimum)) {
                    forward[index][i] -= minimum[0];
                    backward[i][index] += minimum[0];
                    return true;
                }
                minimum[0] = oldMinimum;
            }
        }
        return false;
    } else { //siMode
        visitedSi[index] = true;
        let total = si[index].total;
        if (total > 0) {
            minimum[0] = Math.min(minimum[0], total);
            si[index].total -= minimum[0];
            return true;
        }

        for (let i = so.length - 1; i >= 0; i -= 1) {
            if (!visitedSo[i] && backward[index][i] > 0) {
                let oldMinimum = minimum[0];
                minimum[0] = Math.min(oldMinimum, backward[index][i]);
                if (search(so, forward, backward, si, i, visitedSo, visitedSi, true, minimum)) {
                    backward[index][i] -= minimum[0];
                    forward[i][index] += minimum[0];
                    return true;
                }
                minimum[0] = oldMinimum;
            }
        }
        return false;
    }
}

const setsAreEqual = (xs, ys) =>
    xs.size === ys.size &&
    [...xs].every((x) => ys.has(x));