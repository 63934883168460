import React from 'react';
import DynamicScheduleCard from '../../components/DynamicScheduleCard';
import DynamicSlotList from '../../components/DynamicSlotList';
import DynamicSchedulerPage from './DynamicSchedulerPage';
import { generateNclSlotContent } from '../../utils/SlotContentFactory';
import { capitalize } from '../../utils/StringUtils';
import {
    ERROR_SOLUTION_MESSAGE,
    FAILURE_SOLUTION_MESSAGE,
    INVALID_SLOT_DELETE_MESSAGE,
    MAX_SLOT_ADD_MESSAGE,
    NO_SOLUTION_MESSAGE,
    PARTIAL_SOLUTION_MESSAGE,
    SLOTS_PER_PLAN,
    UNION_SCHEDULER_NAME
} from '../../utils/Constants';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {getColor} from "../../utils/ColorMapper";
import {DynamicTabbedMultiDisplayTable} from "../../components/DynamicTabbedMultiDisplayTable";

class DynamicUnionSchedulerPage extends DynamicSchedulerPage {
    static SOLVER_NAME = "separate_time";
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <DynamicScheduleCard key="scheduleCard" icon="/union_icon.png" {...this.scheduleProps()}/>,
            slotList: () => <DynamicSlotList key="slotList" contentFactory={generateNclSlotContent(this.state.time)} {...this.slotProps()}/>,
            displayTable: () => <DynamicTabbedMultiDisplayTable key="displayTable" processRequest={this.processRequest}
                processResponse={this.processResponse} solverProps={{name: DynamicUnionSchedulerPage.SOLVER_NAME, maxSolutions: 10}} {...this.displayProps()} />
        }
    }

    openDialog (action, passthroughVars) {
        return () => {
            let limit = SLOTS_PER_PLAN[this.props.id][this.state.planName];
            switch(action) {
                case "add":
                    if (this.slotsRef.current.getSize() >= limit) {
                        this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max ${limit})`});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                case "delete":
                    if (this.state.scheduleActive || this.state.displayActive || this.slotsRef.current.getSize() === 0) {
                        this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                default:
                    super.openDialog(action, passthroughVars)();
            }
        }
    }

    async findSolution(timesMap, intervalsMaps, metadata) {
        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};
        let input = this.processRequest(timesMap, intervalsMaps, metadata);
        let response = await this.callSolver(DynamicUnionSchedulerPage.SOLVER_NAME, input);
        return this.processResponse(response, metadata);
    }

    processRequest(timesMap, intervalsMaps, metadata) {
        let slotsInfo = metadata["slotsInfo"];
        //console.log("processRequest", timesMap, intervalsMaps, slotsInfo);
        let slots = intervalsMaps.map((intervalsMap, index) => { return {"rows": intervalsMap, "length": slotsInfo["lengthArray"][index]}; });
        return {"schedule":{"rows": timesMap}, "slots": slots};
    }

    processResponse(response, metadata) {
        if (response === undefined) {
            return [{messages: [{severity: "error", content: ERROR_SOLUTION_MESSAGE}]}];
        }
        let slotsInfo = metadata["slotsInfo"];
        let messages = [];
        if (response.status.result === "FAILURE") {
            messages.push({severity: "error", content: NO_SOLUTION_MESSAGE});
        } else {
            if (response.status.messageData?.partialIndices) {
                messages.push({
                    severity: "warning",
                    content: `${PARTIAL_SOLUTION_MESSAGE} ${response.status.messageData.partialIndices.map((index) => slotsInfo["nameArray"][index]).join(", ")}`
                });
            }
            if (response.status.messageData?.failureIndices) {
                messages.push({
                    severity: "warning",
                    content: `${FAILURE_SOLUTION_MESSAGE} ${response.status.messageData.failureIndices.map((index) => slotsInfo["nameArray"][index]).join(", ")}`
                });
            }
        }

        let solutionData = {};
        for (let rowIndex of Object.keys(response.schedule.table)) {
            let row = response.schedule.table[rowIndex].map((entry) => {
                let index = entry.data.index;
                if (entry.categorization === "VALID") {
                    return {"interval": [entry.start, entry.end], "borderColor": getColor(slotsInfo["colorArray"][index], 800), "color": getColor(slotsInfo["colorArray"][index], 500), "label": [slotsInfo["nameArray"][index]]};
                } else {
                    return {"interval": [entry.start, entry.end], "borderColor": getColor(slotsInfo["colorArray"][index], 500), "color": getColor(slotsInfo["colorArray"][index], 200), "label": [`${slotsInfo["nameArray"][index]} (out of bounds)`]};
                }
            });
            if (row.length > 0) {
                solutionData[rowIndex] = row;
            }
        }
        return [{messages: messages, table: {solutionData: solutionData}}];
    }

    componentDidMount() {
        super.componentDidMount();
        document.title = `${capitalize(UNION_SCHEDULER_NAME)} schedule | Snug Scheduler`;
    }
}

export default withErrorBoundary(DynamicUnionSchedulerPage)