import React, { useEffect, useState, useRef } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { db } from "../firebase";
import { query, onSnapshot, where, getDocs, addDoc, collection, orderBy} from "firebase/firestore"; 
import { useAuth } from "./AuthContext";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import { capitalize } from '../utils/StringUtils';
import Snackbar from '@mui/material/Snackbar';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import { useSearchParams } from 'react-router-dom';
import { getColor } from '../utils/ColorMapper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    SCHEDULE_ACCESS_FEATURE,
    ANNUALLY,
    ERROR_TRY_AGAIN_MESSAGE,
    EXTRA_LARGE_PLAN_NAME,
    FULL_ACCESS_FEATURE,
    LARGE_PLAN_NAME,
    MAIN_BUTTON_TEXT,
    MEDIUM_PLAN_NAME,
    MONTHLY,
    PLAN_SLOT_MAP,
    SAVE_FEATURE,
    SHARE_FEATURE,
    SLOT_FEATURE_TEMPLATE,
    SMALL_PLAN_NAME,
    PRICING_TITLE,
    SLOTS_PER_PLAN,
    MULTI_SOLUTION_FEATURE,
    SLOT_ACCESS_TABLE_DESCRIPTION, SLOT_ACCESS_TABLE_SUB_DESCRIPTION, SLOT_ACCESS_TABLE_TITLE
} from '../utils/Constants';

export default function Pricing() {
    const {currentUser} = useAuth();
    const [searchParams] = useSearchParams();

    const intervalDivisionMap = {month: 1, year: 12};
    const [alignment, setAlignment] = useState(searchParams.get("alignment") || 'month');
    const [tiers, setTiers] = useState(["free"]);
    const [features, setFeatures] = useState([[SCHEDULE_ACCESS_FEATURE, SLOT_FEATURE_TEMPLATE.replace("{0}", 6)]]);
    const [uniqueFeatures, setUniqueFeatures] = useState([[]]);
    const [prices, setPrices] = useState([{month: 0, year: 0}]);
    const [buttonTexts, setButtonTexts] = useState([MAIN_BUTTON_TEXT])
    const [buttonTypes, setButtonTypes] = useState(["contained"])
    const [priceIds, setPriceIds] = useState([{month: undefined, year: undefined}])
    const [loading, setLoading] = useState(undefined);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const buttonAction = useRef(() => () => {});

    useEffect(() => {
        if (currentUser) {
            document.title = PRICING_TITLE + " | Snug Scheduler";
            const unsubscribe = onSnapshot(query(collection(db, "products"), where('active', '==', true), orderBy("metadata.planIndex")), (querySnapshot) => {
                let newData = [];
                let productIdIndexMap = {};
                let index = 1;
                querySnapshot.forEach((product) => {
                    let datapoint = {};
                    datapoint.tier = product.data().role;
                    datapoint.features = product.data().stripe_metadata_features && JSON.parse(product.data().stripe_metadata_features);
                    datapoint.uniqueFeatures = product.data().stripe_metadata_uniqueFeatures && JSON.parse(product.data().stripe_metadata_uniqueFeatures);
                    datapoint.buttonText = "Select Plan";
                    datapoint.buttonType = "outlined";
                    productIdIndexMap[product.id] = index;
                    newData.push(datapoint);
                    getDocs(query(collection(db, "products", product.id, "prices")), orderBy("unit_amount")).then((querySnapshot) => {
                        let priceDict = {};
                        let priceIdDict = {};
                        querySnapshot.forEach((price) => {
                            let interval = price.data().recurring.interval;
                            priceDict[interval] = (price.data().unit_amount / 100 / intervalDivisionMap[interval]).toFixed(2);
                            priceIdDict[interval] = price.id;
                        });
                        prices[productIdIndexMap[product.id]] = priceDict;
                        priceIds[productIdIndexMap[product.id]] = priceIdDict;
                        setPrices([...prices]);
                        setPriceIds([...priceIds]);
                    });
                    index += 1;
                });
                setTiers(tiers.concat(newData.map((datapoint) => datapoint.tier)));
                setFeatures(features.concat(newData.map((datapoint) => datapoint.features)));
                setUniqueFeatures(uniqueFeatures.concat(newData.map((datapoint) => datapoint.uniqueFeatures)));
                setButtonTexts(buttonTexts.concat(newData.map((datapoint) => datapoint.buttonText)));
                setButtonTypes(buttonTypes.concat(newData.map((datapoint) => datapoint.buttonType)));
            });

            buttonAction.current = (index, context) => {
                return async () => {
                    if (context.priceId === undefined) {
                        window.location.assign("/explore");
                    } else {
                        setLoading(index);
        
                        const docRef = await addDoc(collection(db, "customers", currentUser.uid, 'checkout_sessions'), {
                            price: context.priceId,
                            allow_promotion_codes:true,
                            success_url: `${window.location.origin}/settings`,
                            cancel_url: window.location.origin,
                        });
                        onSnapshot(docRef, (snap) => {
                            const { error, url } = snap.data();
                            if (error) {
                                setLoading(undefined);
                                setSnackbarOpen(true);
                            }
                            if (url) {
                                window.location.assign(url);
                            }
                        });
                    }
                }
            }

            return () => {
                unsubscribe()
            };
        } else {
            document.title = PRICING_TITLE;
            setPrices(prices.concat([
                {month: "2.99", year: "2.00"}, 
                {month: "9.99", year: "8.50"}, 
                {month: "19.99", year: "17.00"},
                {month: "49.99", year: "42.50"}
            ]));
            setTiers(tiers.concat([SMALL_PLAN_NAME , MEDIUM_PLAN_NAME , LARGE_PLAN_NAME , EXTRA_LARGE_PLAN_NAME ]));
            setFeatures(features.concat([
                [SCHEDULE_ACCESS_FEATURE],
                [SCHEDULE_ACCESS_FEATURE, FULL_ACCESS_FEATURE, SAVE_FEATURE, SHARE_FEATURE, MULTI_SOLUTION_FEATURE],
                [SCHEDULE_ACCESS_FEATURE, FULL_ACCESS_FEATURE, SAVE_FEATURE, SHARE_FEATURE, MULTI_SOLUTION_FEATURE],
                [SCHEDULE_ACCESS_FEATURE, FULL_ACCESS_FEATURE, SAVE_FEATURE, SHARE_FEATURE, MULTI_SOLUTION_FEATURE]
            ]));
            setUniqueFeatures(uniqueFeatures.concat([
                [FULL_ACCESS_FEATURE, SAVE_FEATURE, SHARE_FEATURE, SLOT_FEATURE_TEMPLATE.replace("{0}", PLAN_SLOT_MAP[SMALL_PLAN_NAME]), MULTI_SOLUTION_FEATURE],
                [SLOT_FEATURE_TEMPLATE.replace("{0}", PLAN_SLOT_MAP[MEDIUM_PLAN_NAME])],
                [SLOT_FEATURE_TEMPLATE.replace("{0}", PLAN_SLOT_MAP[LARGE_PLAN_NAME])],
                [SLOT_FEATURE_TEMPLATE.replace("{0}", PLAN_SLOT_MAP[EXTRA_LARGE_PLAN_NAME])]
            ]))
            setButtonTexts(buttonTexts.concat(["Select Plan", "Select Plan", "Select Plan",  "Select Plan"]));
            setButtonTypes(buttonTypes.concat(["outlined", "outlined", "outlined", "outlined"]));
            buttonAction.current = (index, context) => {
                return () => {
                    window.location.assign("/login?redirect=" + encodeURIComponent(`/pricing?tier=${context.tier}&alignment=${context.alignment}`));
                }
            }
            return undefined;
        }
    }, []);


    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.action.hover,
            color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 16,
        },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
          }
    }));

    return (
        <Container maxWidth="xl">
            <h1>{PRICING_TITLE}</h1>
                <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                sx= {{mb: 2}}
            >
                <ToggleButton value="month">{MONTHLY}</ToggleButton>
                <ToggleButton value="year">{ANNUALLY}</ToggleButton>
            </ToggleButtonGroup>
            <Box display="flex" flexWrap="wrap" gap={1} mb={10}>
                    {tiers.map((tier, index) => {
                        return <Card key={index} variant="outlined" sx={{ display: "flex", flexDirection: "column", borderColor: searchParams.get("tier") === tier && getColor("blue", 300)}} style={{flexGrow: 1, flexBasis: "30ch", backgroundColor: searchParams.get("tier") === tier && getColor("blue", 50)}} >
                            <CardContent>
                                <Typography sx={{ mb: 2}} variant="h5" component="div">
                                    {capitalize(tier)}
                                </Typography>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Typography sx={{ mb: 3}} variant="h4" component="div">
                                        ${prices[index] ? prices[index][alignment]:  ""}
                                    </Typography>
                                    <Typography sx={{ mb: 3}} variant="h6" component="div">
                                        /month
                                    </Typography>
                                </Stack>
                                <List sx={{listStylePosition: 'inside'}}>
                                    {features[index].map((point, i) => {
                                        return <ListItem key={i}>
                                            <ListItemText primary={point}/>
                                        </ListItem>
                                    })}
                                </List>
                                { uniqueFeatures[index] && uniqueFeatures[index].length > 0 &&
                                <React.Fragment>
                                    <Divider /> 
                                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}} >
                                        {uniqueFeatures[index].map((point, i) => {
                                            return <ListItem key={i}>
                                                <ListItemIcon style={{minWidth: '30px'}}>
                                                    <CheckIcon color="success"  fontSize='small'/>
                                                </ListItemIcon>
                                                <ListItemText primary={point}/>
                                            </ListItem>
                                        })}
                                    </List>
                                </React.Fragment>
                                }
                            </CardContent>
                            <CardActions sx={{marginTop: "auto"}} >
                                <Button fullWidth variant={buttonTypes[index]} disabled={loading !== undefined} size="large" sx={{m: 1, fontWeight: 700}}
                                    onClick={buttonAction.current(index, {tier: tier, alignment: alignment, priceId: priceIds?.[index]?.[alignment]})}>
                                    {buttonTexts[index]}
                                    { loading === index && <CircularProgress size="1.5rem" sx={{ position: 'absolute', right: '20px' }} /> }
                                </Button>
                            </CardActions>
                        </Card>
                    })}
            </Box>

            <Box>
                <Typography variant="h6" component="div">
                    {SLOT_ACCESS_TABLE_TITLE}
                </Typography>
                <Typography variant="body1" component="div" mb={2}>
                    {`${SLOT_ACCESS_TABLE_DESCRIPTION} ${SLOT_ACCESS_TABLE_SUB_DESCRIPTION}`}
                </Typography>
            </Box>

            <TableContainer variant='outlined' component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell/>
                            {tiers.map((tier, index) => {
                                return <StyledTableCell sx={searchParams.get("tier") === tier && {border: 1.5, borderBottom: 1, borderColor: getColor("blue", 300), backgroundColor: getColor("blue", 50), fontWeight: "bold"}} key={index} align='center'>{capitalize(tier)}</StyledTableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {Object.keys(SLOTS_PER_PLAN).map((scheduleType, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">{capitalize(scheduleType)} scheduler</StyledTableCell>
                            {tiers.map((tier, index) => {
                                return <StyledTableCell sx={searchParams.get("tier") === tier
                                    && {borderLeft: 1.5, borderRight: 1.5, borderColor: getColor("blue", 300),
                                        backgroundColor: getColor("blue", 50), fontWeight: "bold"}} key={index}
                                        align='center'>{SLOTS_PER_PLAN[scheduleType][tier]}</StyledTableCell>;
                            })}
                        </StyledTableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} message={ERROR_TRY_AGAIN_MESSAGE} />

        </Container>
    );
}