import React from "react";
import { Avatar, Box, Typography } from "@mui/material";

const ChatMessage = (props) => {
    let message = [props.message.replace(/【.*?】/g, '')];

    function boldify(inputString) {
        // Split the string by '**'
        const parts = inputString.split('**');
    
        // Map over the parts and return either a bold or normal string
        const elements = parts.map((part, index) => {
            if (index % 2 === 0) {
                // Even indices are not surrounded by '**', so they are normal text
                return part;
            } else {
                // Odd indices are surrounded by '**', so they are bold text
                return <b key={index}>{part}</b>;
            }
        });
    
        // Return the elements as a single component
        return elements;
    }

    function urlify(inputString) {
        // Regular expression to match URLs
        const urlRegex = /((?:https?:\/\/)?(?:www\.)?snugscheduler\.com[a-zA-Z0-9/_-]*)/;

        // Split the string by URLs
        const parts = inputString.split(urlRegex);

        // Map over the parts and check if each part is a URL or regular text
        const elements = parts.map((part, index) => {
            if (urlRegex.test(part)) {
                // The part is a URL
                const path = part.replace(/(?:https?:\/\/)?(?:www\.)?snugscheduler\.com/, '');
                return <a href={path} key={index} target="_blank" rel="noreferrer">{part}</a>;
            } else {
                // The part is regular text
                return part;
            }
        });

        // Return the elements
        return elements;
    }
    
    return <Box sx={{display: "flex", flexDirection: "row", justifyContent: "flex-start", mb: 2, whiteSpace: 'normal'}}>
        <Avatar sx={{ width: "36px", height: "36px", bgcolor: props.color, mr: 1 }} src={props.avatar}>{props.sender[0].toUpperCase()}</Avatar>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>
            <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>{props.sender}</Typography>
            <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body1">{apply(apply(message, urlify), boldify)}</Typography>
        </Box>
    </Box>

    function apply(messageArray, fn) {
        return messageArray.flatMap((message) => {
            if (typeof message === 'string') {
                return fn(message);
            }
            return message;
        });
    }
}



export default ChatMessage;