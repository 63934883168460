export function initializeSolutionDocumentData(data) {
	if (data.metadata.start) {
		data.metadata.start = data.metadata.start.toDate();
	}
	if (data.metadata.startDate) {
		data.metadata.startDate = data.metadata.startDate.toDate();
	}
	if (data.times) {
		data.times = JSON.parse(data.times);
	}
	if (data.intervalsMaps) {
		data.intervalsMaps = JSON.parse(data.intervalsMaps);
	}
}