import React from "react";
import { Table } from "./Table";
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Undo from '@mui/icons-material/Undo';
import Redo from '@mui/icons-material/Redo';
import Typography from '@mui/material/Typography';
import lodash from "lodash";
import Event from "./Event";
import { getCtrlSymbol, isMac } from "../utils/PlatformUtils";

export class ActionTable extends Table {
	constructor(props) {
		super(props);
		this.state = {history: [], historyIndex: -1};
		this.tableRef = React.createRef();
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	lodashEventCustomizer = (objValue, othValue) => {
		if (objValue instanceof Event && othValue instanceof Event) {
			return objValue.isEqual(othValue);
		}
		return undefined;
	};

	cacheData = (data) => {
		if (this.state.historyIndex < 0 || this.state.historyIndex >= this.state.history.length || !lodash.isEqualWith(data, this.state.history[this.state.historyIndex], this.lodashEventCustomizer)) {
			const HISTORY_LIMIT = 5;
			const newHistory = this.state.history.slice(Math.max(0, this.state.history.length - HISTORY_LIMIT), this.state.historyIndex + 1);
			newHistory.push(lodash.cloneDeep(data));
			this.setState({history: newHistory, historyIndex: newHistory.length - 1});
		}
    }

	getIndexFromX(X) {
		return Math.floor(X / this.getWidth());
	}
	
	getIndexFromY(Y) {
		return Math.min(Math.max(0, Math.floor(Y / this.props.height - 1)), this.props.tableRows.length - 1);
	}

	getMouseY(event) {
		return this.getMouseYFromPosition(event.pageY);
	}

	getMouseX(event) {
		return this.getMouseXFromPosition(event.pageX);
	}

	getMouseYFromPosition(Y) {
		return Y - this.tableRef.current.offsetTop + this.props.parentRef.current.scrollTop;
	}

	getMouseXFromPosition(X) {
		return X - this.tableRef.current.offsetLeft + this.props.parentRef.current.scrollLeft - this.getWidth();
	}

	onMouseMove = (event) => {
		this.onMoveEventTriggerCount += 1;
		
		if (this.onMoveEventTriggerCount >= 5 && this.activeMouseEvent !== undefined) {
			this.activeMouseEvent(event);
		}
	}
    
	onMouseUp = (event) => {
		if (this.onMoveEventTriggerCount >= 5) {
			if (this.onClickEvent !== undefined) {
				this.onClickEvent(event);
			}
		} else {
			if (this.clickOnlyEvent !== undefined) {
				this.clickOnlyEvent(event);
			}
		}
		this.activeMouseEvent = undefined;
		this.onMoveEventTriggerCount = 0;
		this.clickOnlyEvent = undefined;
		this.onClickEvent = undefined;
	}
    
	onMouseDown(initFn, moveFn, clickFn, clickOnlyFn) {
		return (event) => {
			event.stopPropagation();
			if (this.state.rightClickX !== undefined || this.state.rightClickY !== undefined) {
				this.handleClick();
			} else if (this.activeMouseEvent === undefined) {
				this.onMoveEventTriggerCount = 0;
				this.activeMouseEvent = moveFn;
				this.onClickEvent = clickFn;
				this.clickOnlyEvent = clickOnlyFn;
				initFn(event);
			}
		}
	}

	onRightMouseDown(initFn, moveFn, clickFn, clickOnlyFn) {
		return (event) => {
			if (event.button === 0) {
				this.onMouseDown(initFn, moveFn, clickFn, clickOnlyFn)(event);
			}
		}
	}

	onRightMouseUp = (event) => {
		if (event.button === 0) {
			this.onMouseUp(event);
			this.cacheData(this.state.data);
		}
	}

	handleContextMenu = (event) => {
		event.preventDefault();
		this.setState({rightClickX: event.pageX, rightClickY: event.pageY});
	}
	
	handleClick = () => {
		this.setState({rightClickX: undefined, rightClickY: undefined});
	}

	handleKeyDown(event) {
		const ctrlKey = isMac() ? event.metaKey : event.ctrlKey;
	
		if (ctrlKey && !event.shiftKey && (event.key === 'C' || event.key === 'c')) {
			event.preventDefault();
			event.stopPropagation();
			this.copyAction();
		}
		if (ctrlKey && !event.shiftKey && (event.key === 'V' || event.key === 'v')) {
			event.preventDefault();
			event.stopPropagation();
			this.pasteAction();
		}
		if (ctrlKey && event.shiftKey && (event.key === 'V' || event.key === 'v')) {
			event.preventDefault();
			event.stopPropagation();
			this.pasteAllAction();
		}
		if (ctrlKey && !event.shiftKey && (event.key === 'Z' || event.key === 'z')) {
			event.preventDefault();
			event.stopPropagation();
			this.undoAction();
		}
		if (ctrlKey && event.shiftKey && (event.key === 'Z' || event.key === 'z')) {
			event.preventDefault();
			event.stopPropagation();
			this.redoAction();
		}
		if (ctrlKey && !event.shiftKey && (event.key === 'Y' || event.key === 'y')) {
			event.preventDefault();
			event.stopPropagation();
			this.redoAction();
		}
	}


	handleMouseMove = (event) => {
		this.setState({
			mouseX: this.getMouseX(event),
			mouseY: this.getMouseY(event),
		});
	}

	componentDidMount() {
		super.componentDidMount();
		document.addEventListener("click", this.handleClick);
		document.addEventListener('keydown', this.handleKeyDown);
		document.addEventListener('mousemove', this.handleMouseMove);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleClick);
		document.removeEventListener('keydown', this.handleKeyDown);
		document.removeEventListener('mousemove', this.handleMouseMove);
	}

	additionalMenuItems() {
		return [];
	}

	copyAction = (event) => {
		let [data, selectAll, ] = this.returnData();
		let clipboard = {data: data, selectAll: selectAll};
		if (this.state.mouseX !== undefined && this.state.mouseY !== undefined) {
			let mouseX = this.state.mouseX;
			let mouseY = this.state.mouseY;
			if (event !== undefined) {
				mouseX = this.getMouseXFromPosition(this.state.rightClickX);
				mouseY = this.getMouseYFromPosition(this.state.rightClickY);
			}
			let row = this.getIndexFromY(mouseY);
			let col = this.getIndexFromX(mouseX);
			if (row >= 0 && row < this.props.tableRows.length && col >= 0 && col < this.props.tableCols.length) {
				clipboard.rowIndex = row;
			}
		}
		this.setState({clipboard: clipboard});
	}

	pasteAction = (event) => {
		if (this.state.clipboard !== undefined) {
			let clipboard = this.state.clipboard;
			let mouseX = this.state.mouseX;
			let mouseY = this.state.mouseY;
			if (event !== undefined) {
				mouseX = this.getMouseXFromPosition(this.state.rightClickX);
				mouseY = this.getMouseYFromPosition(this.state.rightClickY);
			}
			let row = this.getIndexFromY(mouseY);
			let col = this.getIndexFromX(mouseX);
			if (row >= 0 && row < this.props.tableRows.length && col >= 0 && col < this.props.tableCols.length) {
				this.loadRow(clipboard.data, clipboard.rowIndex, row);
			}
		}
	}

	pasteAllAction = () => {
		if (this.state.clipboard !== undefined) {
			let clipboard = this.state.clipboard;
			this.loadData(clipboard.data, clipboard.selectAll, true, clipboard, true);
		}
	}

	undoAction = () => {
        if (this.state.historyIndex > 0 && this.state.history[this.state.historyIndex - 1] !== undefined) {
			this.arr = lodash.cloneDeep(this.state.history[this.state.historyIndex - 1]);
            this.setState({
                data: this.arr,
				historyIndex: this.state.historyIndex - 1,
            });
        }
    }

	redoAction = () => {
        if (this.state.historyIndex < this.state.history.length - 1 && this.state.history[this.state.historyIndex + 1] !== undefined) {
			this.arr = lodash.cloneDeep(this.state.history[this.state.historyIndex + 1]);
            this.setState({
				data: this.arr,
				historyIndex: this.state.historyIndex + 1,
            });
        }
    }

	render(table) {
		let additionalMenuItems = this.additionalMenuItems();
		let menu = <Paper sx={{ width: 230, maxWidth: '60%', left: this.state.rightClickX, top: this.state.rightClickY, zIndex: 999, position: "absolute"}}>
			<MenuList>
				<MenuItem onClick={this.copyAction}>
					<ListItemIcon>
						<ContentCopy fontSize="small" />
					</ListItemIcon>
					<ListItemText>Copy</ListItemText>
					<Typography variant="body2" sx={{ color: 'text.secondary' }}>
						{getCtrlSymbol()}C
					</Typography>
				</MenuItem>
				{this.state.clipboard && <MenuItem onClick={this.pasteAction}>
					<ListItemIcon>
						<ContentPaste fontSize="small" />
					</ListItemIcon>
					<ListItemText>Paste Row</ListItemText>
					<Typography variant="body2" sx={{ color: 'text.secondary' }}>
						{getCtrlSymbol()}V
					</Typography>
				</MenuItem>}
				{this.state.clipboard && <MenuItem onClick={this.pasteAllAction}>
					<ListItemIcon>
						<ContentPaste fontSize="small" />
					</ListItemIcon>
					<ListItemText>Paste All</ListItemText>
					<Typography variant="body2" sx={{ color: 'text.secondary' }}>
						{getCtrlSymbol()}+Shift+V
					</Typography>
				</MenuItem>}
				{this.state.historyIndex > 0 && <MenuItem onClick={this.undoAction}>
                    <ListItemIcon>
                        <Undo fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Undo</ListItemText>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {getCtrlSymbol()}Z
                    </Typography>
                </MenuItem>}
				{this.state.history.length >= 0 && this.state.historyIndex < this.state.history.length - 1 && <MenuItem onClick={this.redoAction}>
                    <ListItemIcon>
                        <Redo fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Redo</ListItemText>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
						{getCtrlSymbol()}Y
                    </Typography>
                </MenuItem>}
				{additionalMenuItems.length === 0 || <Divider /> }
				{additionalMenuItems.map((item) => item)}
			</MenuList>
		</Paper>;

		return <div onContextMenu={this.handleContextMenu}>
			{table}
			{this.state.rightClickX && this.state.rightClickY && menu}
		</div>
	}

	loadRow = (data, dataRowIndex, index) => {
		this.arr = [...this.arr];
		this.arr[index] = this.getInternalArr(data, true)[dataRowIndex];
		this.cacheData(this.arr);
		this.setState({data: this.arr})
	}
}