import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function TextPage(props) {
    return (
        <Container maxWidth="xl" sx={{mt: 10}}>
            <Typography align="center" variant="h6"><strong>{props.text}</strong></Typography>
            <Typography align="center" variant="subtitle1">{props.subtext}</Typography>
        </Container>
    )
}