//timesMap: {0: [[a, b], [c, d]], 5: ... }
//intervalsMaps: [ { 0: [[a, b], [c, d]], 5: ... } ]
export default function findSolution(timesMap, intervalsMaps) {
    //overlapMap: {0: [5: {0, 1, 2}, 6: {0, 1, 2}, 7: {0, 2}], 5: [4: {1, 2}]}
    let overlapMap = {};
    for (let i = 0; i < intervalsMaps.length; i += 1) {
        let intervalsMap = intervalsMaps[i];
        for (let key in intervalsMap) {
            let row = intervalsMap[key];
            if (!(key in overlapMap)) {
                overlapMap[key] = [];
            }
            for (let j = 0; j < row.length; j += 1) {
                let interval = row[j];
                for (let val = interval[0]; val < interval[1]; val += 1) {
                    if (overlapMap[key][val] === undefined) {
                        overlapMap[key][val] = new Set();
                    }
                    overlapMap[key][val].add(i);
                }
            }
        }
    }

    let filteredOverlapMap = {};
    for (let key in timesMap) {
        if (overlapMap[key] === undefined) {
            continue;
        }
        let row = timesMap[key];
        for (let i = 0; i < row.length; i += 1) {
            let interval = row[i];
            for (let j = interval[0]; j < interval[1]; j += 1) {
                if (overlapMap[key][j] !== undefined) {
                    if (filteredOverlapMap[key] === undefined) {
                        filteredOverlapMap[key] = [];
                    }
                    filteredOverlapMap[key][j] = overlapMap[key][j];
                }
            }
        }
    }

    //compressedOverlapMap: {0: [{interval: [a, b], set: {0, 1, 2}}, {interval: [c, d], set: {0, 1, 2}}], 5: [{interval: [e, f], set: {1, 2}}]}
    let compressedOverlapMap = {};
    for (let key in filteredOverlapMap) {
        let row = filteredOverlapMap[key];
        compressedOverlapMap[key] = [];
        let startIndex = undefined;
        let previousIndex = undefined;
        let previousSet = undefined;
        for (let index = 0; index < row.length; index += 1) {
            let set = row[index];
            if (set === undefined || index !== previousIndex + 1 || (previousSet !== undefined && !setsAreEqual(set, previousSet))) {
                if (startIndex !== undefined) {
                    compressedOverlapMap[key].push({interval: [startIndex, previousIndex + 1], set: previousSet});
                }
                startIndex = set !== undefined ? index : undefined; 
            }
            if (set !== undefined) {
                previousIndex = index;
                previousSet = set;
            }
        }
        if (previousSet !== undefined && startIndex !== undefined) {
            compressedOverlapMap[key].push({interval: [startIndex, previousIndex + 1], set: previousSet});
        }
    }

    return compressedOverlapMap;
}

const setsAreEqual = (xs, ys) =>
    xs.size === ys.size &&
    [...xs].every((x) => ys.has(x));