import React from "react";
import SlotList from './SlotList';
import Slot from "./Slot";

export default class StaticSlotList extends SlotList {
    constructor(props) {
        super(props);
        this.Slot = <Slot />;
        this.count = 0;
        this.dataAttributes = {};
        let data = this.props.data || [];
        for (var i = 0; i < data.length; i += 1) {
            let key = this.count++;
            this.slotRefs[key] = React.createRef();
            this.dataAttributes[key] = data[i].attributes;
        }
        this.state.orderToSlotIndex = [...Array(data.length).keys()]
        if (this.state.orderToSlotIndex.length > 0) {
            this.state.activeSlotId = this.state.orderToSlotIndex[0];
        }
    }

    addSlot(slotName) {
        super.addSlot(slotName, this.count++);
    }
}