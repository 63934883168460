import React, { useEffect, useState } from "react"
import Container from "@mui/material/Container"
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { addDoc, collection, serverTimestamp} from "firebase/firestore"; 
import { db } from "../firebase"
import { withAuth } from "./AuthContext";
import CircularProgress from '@mui/material/CircularProgress';
import TextPage from './TextPage';
import { SUBMIT_ISSUE_NAME, SUBMIT_SUBTEXT, SUBMIT_TEXT } from "../utils/Constants";
import { log } from "../utils/AnalyticsUtils";

const options = ["Report a bug", "Request a feature", "Provide general feedback"];

const SubmitIssuePage = (props) => {
    const [issue, setIssue] = useState('');
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [firstSubmitted, setFirstSubmitted] = useState(false);

    useEffect(() => {
        if (loading && issue && text) {
            addDoc(collection(db, "feedback"), {issue: issue, text: text, uid: props.auth.currentUser.uid, createdAt: serverTimestamp()})
                .then(() => {
                    log("button", {name: "Submit Issue"})
                    setSubmitted(true);
                });
        }
    }, [loading, issue, text]);

    useEffect(() => {
        document.title = SUBMIT_ISSUE_NAME;
    }, []);

    const onSubmit = () => {
        if (!issue || !text) {
            setFirstSubmitted(true);
        } else {
            setLoading(true);
        }
    }

    return submitted ? <TextPage text={SUBMIT_TEXT} subtext={SUBMIT_SUBTEXT} /> :
    <Container maxWidth="xl">
        <h1>{SUBMIT_ISSUE_NAME}</h1>
            <Container>
                <Stack gap={3} mt={10} mb={5}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={issue}
                            label="Category"
                            onChange={(event) => setIssue(event.target.value)}
                            error={firstSubmitted && !issue}
                        >
                            {options.map((option, index) => {
                                return <MenuItem key={index} value={option}>{option}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                            fullWidth
                            id="outlined-multiline-static"
                            label="Details"
                            multiline
                            rows={16}
                            value={text}
                            onChange={(event) => setText(event.target.value)}
                            error={firstSubmitted && !text}
                        />
                </Stack>
                <Box sx={{display: "flex", justifyContent: "center"}}>
                    <Button disabled={loading} variant="contained" onClick={onSubmit}>
                        {loading ? <CircularProgress size="1.5rem"/> : "Submit"}
                    </Button>
                </Box>
            </Container>
    </Container>
}

export default withAuth(SubmitIssuePage);