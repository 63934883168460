import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Dialog, TextField } from '@mui/material';
import { DELETE_ACCOUNT_DIALOG_CONFIRMATION, DELETE_ACCOUNT_DIALOG_CONTENT, DELETE_ACCOUNT_DIALOG_TITLE, DELETE_ACCOUNT_DIALOG_POSITIVE } from '../../utils/Constants';
import { log } from '../../utils/AnalyticsUtils';

export default class DeleteAccountPrompt extends React.Component {
	constructor(props) {
		super(props);
		this.state = {open: false, confirmation: ""};
		this.name = this.props.currentUser.displayName  || "I understand, delete my account.";
	}

	handleClickOpen() {
		this.setState({open: true});
	}


	handleClose (success) {
		return () => {
			if (success && this.name === this.state.confirmation) {
				log("button", {name: "Delete Account"})
				this.props.currentUser.delete();
			} else {
				log("button", {name: "Cancel Delete Account"});
			}
			this.setState({open: false});
		}
	}

	render() {
		return  <Dialog open={this.state.open} onClose={this.handleClose}>
			<DialogTitle>{DELETE_ACCOUNT_DIALOG_TITLE}</DialogTitle>
			<DialogContent>
				<DialogContentText variant="body1">{DELETE_ACCOUNT_DIALOG_CONTENT}</DialogContentText>
				<DialogContentText fontWeight="bold" mt={2}>{DELETE_ACCOUNT_DIALOG_CONFIRMATION}</DialogContentText>
				<TextField autoFocus margin="dense" id="name" type="text" fullWidth variant="outlined" placeholder={this.name}
				onChange={(event) => this.setState({confirmation: event.target.value})}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.handleClose(false)}>Cancel</Button>
				<Button color="error" disabled={this.name !== this.state.confirmation} onClick={this.handleClose(true)}>{DELETE_ACCOUNT_DIALOG_POSITIVE}</Button>
			</DialogActions>
		</Dialog>
	}
}