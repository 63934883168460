import React, { useEffect } from 'react';
export const CookiesPage = () => {
    useEffect(() => {
        document.title = "Cookie Policy";
    }, []);
    
    return (
        <div className="cookies">
            <h1>Cookie Policy for Snug Scheduler</h1>
            <p><strong>Effective Date: April 1, 2024</strong></p>

            <h2>1. What Are Cookies</h2>
            <p>Cookies are small pieces of data stored on a user&apos;s device (computer, mobile device, etc.) by a website through the user&apos;s browser. They help websites remember information about the user&apos;s visit, preferences, and activities.</p>

            <h2>2. Types of Cookies Used</h2>
            <h3>Session Cookies</h3>
            <p>These temporary cookies are erased once the user closes the browser. They are essential for website functionality.</p>
            <h3>Persistent Cookies</h3>
            <p>These cookies remain on the user&apos;s device even after closing the browser. They help remember user preferences and improve the experience.</p>

            <h2>3. How We Use Cookies</h2>
            <p>We use cookies for:</p>
            <ul>
                <li><strong>Personalized experience:</strong> Remembering user preferences and settings for future visits.</li>
                <li><strong>Enhanced functionality:</strong> Improving the functionality of our web app.</li>
                <li><strong>Analysis:</strong> Analyzing user behavior to improve our services.</li>
            </ul>

            <h2>4. Third-Party Cookies</h2>
            <p>We may use third-party services that use cookies for analytics, advertising, or other functionalities. These cookies are subject to their own privacy policies.</p>

            <h2>5. User Consent</h2>
            <p>By using Snug Scheduler, you consent to the use of cookies in accordance with this policy. You can manage cookie preferences through your browser settings.</p>

            <h2>6. Managing Cookies</h2>
            <p>You can control cookie settings in your browser and delete existing cookies. Please note that disabling cookies may impact your user experience.</p>

            <h2>7. Changes to the Cookie Policy</h2>
            <p>We reserve the right to update this policy. Any updates will be posted on our website with the new effective date.</p>

            <h2>8. Contact Us</h2>
            <p>For questions or concerns about our Cookie Policy, please contact us at
                <span> support</span>
                <span>@</span>
                <span>snugscheduler.com</span>
                .
            </p>
        </div>
    )
}
