import React, { useEffect } from 'react';

export const PrivacyPage = () => {
    useEffect(() => {
        document.title = "Privacy Policy";
    }, []);
    
    return (
        <div className="privacy">
        <h1>Privacy Policy for Snug Scheduler</h1>
        <p><strong>Effective Date: April 1, 2024</strong></p>
        <p>Thank you for using Snug Scheduler! This privacy policy describes how we collect, use, and protect your personal information when you use our web app. By using Snug Scheduler, you consent to the practices described in this policy.</p>

        <h2>Information We Collect</h2>
        <ul>
            <li><strong>Email Address: </strong>We use your email address to send you important updates regarding your account.</li>
            <li><strong>Schedule Information: </strong>Schedule data inputted by users may be used to enhance algorithms and improve our product. Data will be anonymized and no personal information such as schedule names, titles, or user information will be used in this process.</li>
            <li><strong>AI Chat Messages: </strong>We store chat logs for a period of time and use them to improve SnugAI to ensure relevant and accurate responses.</li>
            <li><strong>Analytics: </strong>We monitor errors and log session information to fix errors and improve user experience.</li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>The data we collect is used <strong>solely</strong> for improving our products and services. We <strong>do not</strong> share this information with third parties.</p>
        <strong>Third Party Service Providers: </strong>We use various third party services and they may collect data which will be subjected to their own data privacy practices. These third party services include:
            <ul>
                <li><a href="https://marketingplatform.google.com/about/analytics/terms/us">Google Analytics</a></li>
                <li><a href="https://openai.com/policies/privacy-policy">OpenAI</a></li>
            </ul>

        <h2>Data Storage and Security</h2>
        <p>Your personal information is stored in a <strong>secure database</strong> that is only accessible by you and authorized personnel.</p>

        <h2>User Control</h2>
        <ul>
            <li>Users can delete any stored schedule information whenever they like, by selecting a schedule to delete in <a href="/schedules"><strong>My Schedules</strong></a> page.</li>
            <li>Users can delete their account by going to <a href="/settings"><strong>Account settings</strong></a> &gt; User data deletion &gt; Permanently delete my account.</li>
        </ul>

        <h2>User Consent</h2>
        <p>By creating an account, users consent to share their email address and name. By using Snug Scheduler and inputting schedule data, you consent to the collection and analysis of your information <strong>for the purposes stated in this Privacy Policy</strong>.</p>

        <h2>Contact Us</h2>
        <p>For further questions or concerns about our privacy practices, please contact support at  
            <span> support</span>
            <span>@</span>
            <span>snugscheduler.com</span>
            .
        </p>
        <p>Please check back periodically for any changes.</p>
        </div>
    )
}
