import {amber, blue, blueGrey, brown, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow} from "@mui/material/colors";

const mapping = {
    "amber": amber, 
    "blue": blue,
    "blueGrey": blueGrey,
    "brown": brown,
    "cyan": cyan,
    "deepOrange": deepOrange,
    "deepPurple": deepPurple,
    "green": green,
    "grey": grey,
    "indigo": indigo, 
    "lightBlue": lightBlue,
    "lightGreen": lightGreen,
    "lime": lime,
    "orange": orange,
    "pink": pink,
    "purple": purple,
    "red": red,
    "teal": teal,
    "yellow": yellow
};

const keyList = Object.keys(mapping);

export function getColors() {
    return Object.keys(mapping);
}

export function getColor(color, shade) {
    if (shade < 100) {
        shade = 50;
    } else {
        shade = Math.min(Math.max(100, Math.round(shade / 100) * 100), 900)
    }
    return mapping[color][shade];
}

export function findNextColor(color) {
    let index = keyList.indexOf(color);
    return keyList[(index + 1) % keyList.length];
}