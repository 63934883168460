// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAnalytics, isSupported } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAUB0UG0XQqVRr12jMY0KecYpyfcT2-Fig",
  authDomain: "snugscheduler.com",
  projectId: "snugauth",
  storageBucket: "snugauth.appspot.com",
  messagingSenderId: "810113618055",
  appId: "1:810113618055:web:573a9eb1a19cf1091d6822"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
//connectAuthEmulator(auth, "http://127.0.0.1:9099");
export const db = getFirestore(app);
//connectFirestoreEmulator(db, '127.0.0.1', 8080);
export const functions = getFunctions(app, "us-central1");
//connectFunctionsEmulator(functions, "localhost", 5001);
let analyticsInstance = undefined;
isSupported().then((supported) => {
  if (supported) {
    analyticsInstance = getAnalytics(app);
  }
});
export function analytics() {
  return analyticsInstance;
}