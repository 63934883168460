import SlotList from "./SlotList";

export default class SlotListList extends SlotList {
    constructor(props) {
        super(props);
        this.slotListRefs = {};
    }

    slotPropertyChange(slotId) {
        return (key, value) => {
            if (key === "color") {
                this.slotListRefs[slotId].current.resetColors(value);
            }
            super.slotPropertyChange(slotId)(key, value);
        }
    }

    slotClickAction(slotId) {
        return () => {
            if (!this.props.selected || this.state.activeSlotId !== slotId) {
                this.setState({activeSlotId: slotId})
                this.props.slotClickAction({color: this.getSlotColor(slotId), data: this.getScheduleData(slotId)});
            }
        }
    }

    childSlotClickAction(slotId) {
        return (attributes) => {
            this.setState({activeSlotId: slotId})
            this.props.slotClickAction(attributes);
        }
    }

    addChildSlot(slotName) {
        if (this.state.activeSlotId !== undefined) {
            this.slotListRefs[this.state.activeSlotId].current.addSlot(slotName);
            this.setExpanded();
        }
    }

    getChildDefaultAttributesContent() {
        return this.slotListRefs[this.state.activeSlotId].current.getDefaultAttributesContent();
    }
    
    setExpanded(slotId=this.state.activeSlotId) {
        for (let otherId in this.slotRefs) {
            if (otherId !== slotId) {
                this.slotRefs[otherId].current.setExpanded(false);
            }
        }
        this.slotRefs[slotId].current.setExpanded(true);
    }

    addSlot(slotName, newSlotKey) {
        if (this.state.activeSlotId === undefined) {
            this.setState({activeSlotId: newSlotKey});
        }
        super.addSlot(slotName, newSlotKey);
    }

    getScheduleData = (slotId=this.state.activeSlotId) => {
        return this.slotListRefs[slotId].current.getScheduleData();
    }

    getSlotColor(slotId=this.state.activeSlotId) {
        return this.slotListRefs[slotId].current.getSlotColor();
    }

    saveDataToActive(data, timeFormat, scheduleStartDay, scheduleEndDay, scheduleStartTime, scheduleEndTime, selectAll) {
        this.slotListRefs[this.state.activeSlotId].current.saveDataToActive(data, timeFormat, scheduleStartDay, scheduleEndDay, scheduleStartTime, scheduleEndTime, selectAll);
    }
    
    deleteActiveSlot() {
        let slotId = this.state.activeSlotId;
        if (!this.props.selected || slotId === undefined) {
            return undefined;
        }
        if (this.slotListRefs[slotId].current.state.orderToSlotIndex.length <= 1) {
            return super.deleteActiveSlot();
        } else {
            this.setExpanded(slotId);
            return this.slotListRefs[slotId].current.deleteActiveSlot();
        }
    }

    getChildSize(slotId=this.state.activeSlotId) {
		return this.slotListRefs[slotId].current.getSize();
	}

    exportAllData(timeFormat) {
        let scheduleDatas = [];
        let scheduleStartDaysList = [];
        let scheduleEndDaysList = [];
        let scheduleStartTimesList = [];
        let scheduleEndTimesList = [];
        let selectAlls = [];
        this.state.orderToSlotIndex.forEach(slotNumber => {
            let [scheduleData, scheduleStartDays, scheduleEndDays, scheduleStartTimes, scheduleEndTimes, selectAll] = this.slotListRefs[slotNumber].current.exportAllData(timeFormat);
            scheduleDatas.push(scheduleData);
            scheduleStartDaysList.push(scheduleStartDays);
            scheduleEndDaysList.push(scheduleEndDays);
            scheduleStartTimesList.push(scheduleStartTimes);
            scheduleEndTimesList.push(scheduleEndTimes);
            selectAlls.push(selectAll);
        });
        return [scheduleDatas, scheduleStartDaysList, scheduleEndDaysList, scheduleStartTimesList, scheduleEndTimesList, selectAlls];
    }

    toString(startDay, endDay, startTime, endTime, startDate, timeFormat) {
        return Object.keys(this.slotRefs).map((slotId) => {
            return `${this.slotRefs[slotId].current.state.name} (${this.slotRefs[slotId].current.state.length} ${timeFormat ? "minutes" : "days"})\n\n`
            + this.slotListRefs[slotId].current.toString(startDay, endDay, startTime, endTime, startDate, timeFormat);
        }).join("\n\n\n");
	}
}