import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PopUpDialog from './PopUpDialog';

export default class DeleteSlotDialog extends PopUpDialog {
	handleClose (success) {
		return () => {
			super.handleClose(success)();
			if (success) {
				this.props.action();
			}
		}
	}

	getDialogContent() {
		return <React.Fragment>
			<DialogTitle>Delete slot</DialogTitle>
			<DialogContent>
				<DialogContentText>Are you sure you want to delete this slot?</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.handleClose(false)}>Cancel</Button>
				<Button onClick={this.handleClose(true)}>Delete</Button>
			</DialogActions>
		</React.Fragment>
	}
}