import React, { useRef, useState } from 'react';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useAuth } from "./AuthContext"
import { capitalize } from '../utils/StringUtils';
import { useEffect } from 'react';
import { Button, CardActionArea, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Stack from '@mui/material/Stack';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import AppleIcon from '@mui/icons-material/Apple';
import { getColor } from '../utils/ColorMapper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
    SCHEDULE_ACCESS_FEATURE,
    FULL_ACCESS_FEATURE_DETAIL,
    FREE_PLAN_NAME,
    PLAN_SLOT_MAP,
    SAVE_FEATURE,
    SAVE_FEATURE_DETAIL,
    SHARE_FEATURE,
    SHARE_FEATURE_DETAIL,
    SLOT_FEATURE_DETAIL_TEMPLATE,
    SLOT_FEATURE_TEMPLATE,
    SCHEDULE_ACCESS_FEATURE_DETAIL,
    FULL_ACCESS_FEATURE,
    FREE_ACCESS_LIMITATIONS,
    PAID_ACCESS_ENCOURAGEMENT,
    CONNECTIONS_DESCRIPTION,
    PLAN_MANAGEMENT_TITLE,
    FEATURES_SUBTITLE_TEMPLATE,
    FEATURES_TITLE,
    CONNECTIONS_TITLE,
    YOUR_PLAN_TITLE,
    CONNECTED_LABEL,
    NOT_CONNECTED_LABEL,
    DELETE_DATA_TITLE,
    DELETE_DATA_DESCRIPTION,
    DELETE_DATA_BUTTON_TEXT,
    MULTI_SOLUTION_FEATURE_DETAIL, MULTI_SOLUTION_FEATURE
} from '../utils/Constants';
import DeleteAccountDialog from '../components/Dialogs/DeleteAccountDialog';
import { log } from '../utils/AnalyticsUtils';

export default function SettingsPage() {
	const {currentUser} = useAuth();
    const deletePromptRef = useRef();
    const [plan, setPlan] = useState("");
    const freeFeatures = [SCHEDULE_ACCESS_FEATURE];
    const freeFeaturesDetail = [SCHEDULE_ACCESS_FEATURE_DETAIL + " " + FREE_ACCESS_LIMITATIONS];
    const paidFeatures = [SCHEDULE_ACCESS_FEATURE, FULL_ACCESS_FEATURE, SAVE_FEATURE, SHARE_FEATURE, MULTI_SOLUTION_FEATURE];
    const paidFeaturesDetail = [SCHEDULE_ACCESS_FEATURE_DETAIL, FULL_ACCESS_FEATURE_DETAIL, SAVE_FEATURE_DETAIL, SHARE_FEATURE_DETAIL, MULTI_SOLUTION_FEATURE_DETAIL];

    useEffect(() => {
        document.title = YOUR_PLAN_TITLE;
		if (currentUser) {
			currentUser.getIdTokenResult(true).then((idTokenResult) => {
                setPlan(idTokenResult.claims.stripeRole || FREE_PLAN_NAME);
			});
		}
	}, []);

    const services = ["Google Calendar", "Microsoft Calendar ", "Apple Calendar"];
    const serviceLogos = [GoogleIcon, MicrosoftIcon, AppleIcon];
    const connected = [false, false, false];

    const features = plan === FREE_PLAN_NAME ? freeFeatures : paidFeatures;
    const featureDetail = plan === FREE_PLAN_NAME ? freeFeaturesDetail : paidFeaturesDetail;

    

    return <Container direction="row" maxWidth="md">
        <Grid container spacing={2} mt={10}>
            <Grid item xs={7} sm={8} md={9}>
                <Card variant="outlined" sx={{p: 3}} >
                    <h4>{YOUR_PLAN_TITLE}</h4>
                    <h1>{capitalize(plan)}</h1>
                </Card>
            </Grid>
            <Grid item xs={5} sm={4} md={3}>
                <Card variant="outlined" sx={{height: "100%"}}>
                    <CardActionArea href={"/plan"} onClick={() => log("button", {name: PLAN_MANAGEMENT_TITLE})} sx={{ height: "100%", display: 'flex', justifyContent: 'center' }} >
                        <Stack direction="column" alignItems="center">
                            <CreditCardIcon sx={{fontSize: {xs: 50, sm: 75}}}/>
                            <Typography variant="body1" textAlign="center" textOverflow="ellipsis">{PLAN_MANAGEMENT_TITLE}</Typography>
                        </Stack>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card variant="outlined" sx={{p: 3}}>
                    <h3>{FEATURES_TITLE}</h3>
                    <Typography >{FEATURES_SUBTITLE_TEMPLATE.replace("{plan}", capitalize(plan))}</Typography>
                    <List sx={{listStylePosition: 'inside'}}>
                        {features.map((paidFeature, index) => {
                            return <ListItem key={index}>
                                <ListItemText primary={paidFeature} primaryTypographyProps={{fontWeight: "bold"}} secondary={featureDetail[index]}/>
                            </ListItem>
                        })}
                        <ListItem key={"numberOfSlots"}>
                            <ListItemText primary={SLOT_FEATURE_TEMPLATE.replace("{0}", PLAN_SLOT_MAP[plan])} primaryTypographyProps={{fontWeight: "bold"}} 
                                secondary={SLOT_FEATURE_DETAIL_TEMPLATE.replace("{0}", PLAN_SLOT_MAP[plan])}/>
                        </ListItem>
                    </List>
                    {plan === FREE_PLAN_NAME && <Typography variant="body2">{PAID_ACCESS_ENCOURAGEMENT}</Typography>}
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card variant="outlined" sx={{p: 3}}>
                    <Box sx={{opacity: 0.25}}>
                        <h3>{CONNECTIONS_TITLE}</h3>
                        <Typography mb={4} >{CONNECTIONS_DESCRIPTION}</Typography>
                        <Box display="flex" flexWrap="wrap" justifyContent="space-evenly" gap={1}>
                            {services.map((service, index) => {
                                let Logo = serviceLogos[index];
                                return <Card key={index}  sx={{ maxWidth: 200 }} style={{flexGrow: 1 }} >
                                    <CardActionArea disabled sx={{ height: "100%", display: 'flex', justifyContent: 'center', p: 2 }}>
                                        <Stack direction="column" alignItems="center" spacing={1}>
                                            <Logo sx={{fontSize:35}}/>
                                            <Typography variant="body1" textAlign="center" sx={{ fontWeight: 'bold' }}>{service}</Typography>
                                            <Typography variant="h5" textAlign="center" sx={{ fontWeight: 'bold', color: getColor(connected[index] ? "green" : "red", 800)}}>{connected[index] ? CONNECTED_LABEL : NOT_CONNECTED_LABEL}</Typography>
                                        </Stack>
                                    </CardActionArea>
                                </Card>
                            })}
                        </Box>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card variant="outlined" sx={{p: 3}}>
                    <h3>{DELETE_DATA_TITLE}</h3>
                    <Typography mt={4} mb={4}>{DELETE_DATA_DESCRIPTION}</Typography>
                    <Button variant="contained" color="error" onClick={() => deletePromptRef.current.handleClickOpen()}>{DELETE_DATA_BUTTON_TEXT}</Button>
                </Card>
            </Grid>
        </Grid>
        <DeleteAccountDialog ref={deletePromptRef} currentUser={currentUser}/>
    </Container>
}