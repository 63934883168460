import React from 'react';
import LoadingPage from '../LoadingPage';
import TextPage from '../TextPage';
import { db } from "../../firebase"
import { doc, getDoc } from "firebase/firestore";
import DynamicUnionSchedulerPage from './DynamicUnionSchedulerPage';
import { withParams } from "../ParamContext";
import DynamicIntersectionSchedulerPage from './DynamicIntersectionSchedulerPage';
import DynamicSelectionSchedulerPage from './DynamicSelectionSchedulerPage';
import DynamicDistributionSchedulerPage from './DynamicDistributionSchedulerPage';
import DynamicFulfillmentSchedulerPage from './DynamicFulfillmentSchedulerPage';
import {
    DISTRIBUTION_SCHEDULER_NAME, FULFILLMENT_SCHEDULER_NAME,
    INTERSECTION_SCHEDULER_NAME,
    NOT_FOUND_SUBTEXT,
    NOT_FOUND_TEXT, SELECTION_SCHEDULER_NAME,
    UNION_SCHEDULER_NAME
} from '../../utils/Constants';
import { log } from '../../utils/AnalyticsUtils';
import {withAuth} from "../AuthContext";


class DynamicSchedulerPageDelegator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {notFound: false, type: undefined};
        getDoc(doc(db, "schedules", this.props.params.id)).then((docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                this.setState({"type": data.type});
            } else {
                this.setState({notFound: true});
            }
        }, () => {
            this.setState({notFound: true})
        });
    }

    render() {
        if (this.state.notFound) {
            return <TextPage text={NOT_FOUND_TEXT} subtext={NOT_FOUND_SUBTEXT} />
        }

        switch (this.state.type) {
            case "union":
                log("schedule_type", {name: "Dynamic Union"});
                return <DynamicUnionSchedulerPage id={UNION_SCHEDULER_NAME} auth={this.props.auth} params={this.props.params}/>
            case "intersection":
                log("schedule_type", {name: "Dynamic Intersection"});
                return <DynamicIntersectionSchedulerPage id={INTERSECTION_SCHEDULER_NAME} auth={this.props.auth} params={this.props.params}/>
            case "selection":
                log("schedule_type", {name: "Dynamic Selection"});
                return <DynamicSelectionSchedulerPage id={SELECTION_SCHEDULER_NAME} auth={this.props.auth} params={this.props.params}/>
            case "distribution":
                log("schedule_type", {name: "Dynamic Distribution"});
                return <DynamicDistributionSchedulerPage id={DISTRIBUTION_SCHEDULER_NAME} auth={this.props.auth} params={this.props.params}/>
            case "fulfillment":
                log("schedule_type", {name: "Dynamic Fulfillment"});
                return <DynamicFulfillmentSchedulerPage id={FULFILLMENT_SCHEDULER_NAME} auth={this.props.auth} params={this.props.params}/>
            default:
                log("schedule_type", {name: "Dynamic"});
                return <React.Fragment key="loadingFragment"><LoadingPage key="scheduleLoading"/></React.Fragment>
        }
    }
}

export default withAuth(withParams(DynamicSchedulerPageDelegator));