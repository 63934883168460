import React, {useState, useRef, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import ChatMessage from '../components/ChatMessage';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useAuth } from './AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import { orange } from "@mui/material/colors";
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase';
import {
    BOT_INTRO_MESSAGE,
    CHAT_DISCLAIMER,
    CHAT_INPUT_PLACEHOLDER,
    CHAT_NAME,
    CHAT_UNAVAILABLE_MESSAGE,
    ERROR_TRY_AGAIN_MESSAGE
} from '../utils/Constants';
import { log } from '../utils/AnalyticsUtils';

const ChatPage = () =>{
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const threadId = useRef();
    const {currentUser} = useAuth();
    const scrollRef = useRef();

    const botDetails = {sender: "SnugAI",  color: orange[100], avatar: "/logo_profile.png"};
    const userDetails = {sender: currentUser.displayName, avatar: currentUser.photoURL};

    const [entries, setEntries] = useState([{sender: botDetails.sender, color: botDetails.color, avatar: botDetails.avatar, message: BOT_INTRO_MESSAGE}]);


    useEffect(() => {
        document.title = CHAT_NAME;

        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behaviour: "smooth" });
        }
    }, [entries]);

    const callAPI = (threadId, input) => {
        return new Promise(resolve => {
            httpsCallable(functions, 'getAssistantResponse')({"threadId": threadId, "input": input})
            .then(response => {                
                resolve(response.data || ERROR_TRY_AGAIN_MESSAGE);
            }).catch(() => {
                resolve(CHAT_UNAVAILABLE_MESSAGE);
            });
        });
    }

    const onSubmit = () => {
        if (value !== "" && !loading) {
            log("button", {name: "Chat submit"})
            let newEntries = [...entries];
            newEntries.push({sender: currentUser.displayName, avatar: userDetails.avatar, message: value});
            setValue("");
            setEntries(newEntries);
            setLoading(true);
            if (threadId.current === undefined) {
                httpsCallable(functions, 'startAssistantThread')()
                .then(response => {
                    threadId.current = response.data;
                    callAPI(threadId.current, value).then((response) => {
                        setLoading(false);
                        setEntries([...newEntries, {sender: botDetails.sender,  color: botDetails.color, avatar: botDetails.avatar, message: response}]);
                    });
                }).catch(() => {
                    setLoading(false);
                    setEntries([...newEntries, {sender: botDetails.sender,  color: botDetails.color, avatar: botDetails.avatar, message: CHAT_UNAVAILABLE_MESSAGE}])
                });
            } else {
                callAPI(threadId.current, value).then((response) => {
                    setLoading(false);
                    setEntries([...newEntries, {sender: botDetails.sender,  color: botDetails.color, avatar: botDetails.avatar, message: response}])
                });
            }
        }
    }

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }

    const onSendButton = () => {
        onSubmit();
    }

    return <Container maxWidth="lg" sx={{height:"100%", p: 2}}>
        <Paper width="100%" variant='outlined' sx={{ boxSizing: "border-box", height:"100%", borderRadius: 5, p: 3, pt: 2}}>
                <Stack height={"100%"}>
                    <Box height="100%" sx={{m: 2, mr: 1, overflowY: "scroll"}}>
                        {entries.map((entry, index) => {
                            return <ChatMessage key={index} color={entry.color} avatar={entry.avatar} sender={entry.sender || "User"} message={entry.message}></ChatMessage>
                        })}
                        <div ref={scrollRef} />
                    </Box>
                    
                    <TextField id="outlined-basic" variant="outlined" placeholder={CHAT_INPUT_PLACEHOLDER} autoComplete='off' value={value} 
                        helperText={CHAT_DISCLAIMER}
                        onKeyDown={onKeyDown} onChange={(event) => setValue(event.target.value)}
                        InputProps={{ sx: { borderRadius: 4 }, 
                            endAdornment: <IconButton type="button" aria-label="search" onClick={onSendButton}>
                                {loading ? <CircularProgress size="1.5rem"/> : <SendIcon /> }
                            </IconButton> }}
                    />
                </Stack>
            </Paper>
    </Container>
}

export default ChatPage;