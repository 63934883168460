import React from "react";
import Event from "./Event"
import { Table } from "./Table";
import Tooltip from '@mui/material/Tooltip';
import {toTimeString, toDateString, addField} from "../utils/DateTimeUtils";
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Typography from '@mui/material/Typography';
import { IconButton } from "@mui/material";
import { getCtrlSymbol, isMac } from "../utils/PlatformUtils";

export class DisplayTable extends Table {
    constructor(props) {
        super(props);
        this.state = {showNames: this.props.showNames};
        this.tableRef = React.createRef();
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleContextMenu = (event) => {
		event.preventDefault();
		this.setState({rightClickX: event.pageX, rightClickY: event.pageY});
	}
	
	handleClick = () => {
		this.setState({rightClickX: undefined, rightClickY: undefined});
	}

    toggleNames = () => {
        this.setState({showNames: !this.state.showNames});
    }

    toggleTooltip = () => {
        this.setState({tooltipOpen: !this.state.tooltipOpen});
    }

    handleKeyDown(event) {
		const ctrlKey = isMac() ? event.metaKey : event.ctrlKey;
        
        if (ctrlKey && !event.shiftKey && (event.key === 'A' || event.key === 'a')) {
            event.preventDefault();
            event.stopPropagation();
            this.toggleNames();
        }
    }

    componentDidMount() {
		super.componentDidMount();
		document.addEventListener("click", this.handleClick);
        document.addEventListener('keydown', this.handleKeyDown);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleClick);
        document.removeEventListener('keydown', this.handleKeyDown);
	}

    gcd = function(a, b) {
        if (!b) {
            return a;
        }
        return this.gcd(b, a % b);
    }

    render() {
        let {messages, solutionData} = this.props.data;
        let factor = this.props.scale;
        for (let rowIndex in solutionData) {
            let row = solutionData[rowIndex];
            for (let j = 0; j < row.length; j += 1) {
                let interval = row[j]["interval"];
                factor = this.gcd(factor, interval[0]);
                factor = this.gcd(factor, interval[1]);
            }
        }
        let minFactor = Math.ceil(this.props.scale * this.props.tableCols.length / 1000);
        factor = Math.max(factor, minFactor);

        let rows = [];
        for (let rowIndex in solutionData) {
            if (rows[rowIndex] === undefined) {
                rows[rowIndex] = [];
            }

            for (let j = 0; j < solutionData[rowIndex].length; j += 1) {
                let interval = solutionData[rowIndex][j]["interval"];
                let color = solutionData[rowIndex][j]["color"];
                let borderColor = solutionData[rowIndex][j]["borderColor"];
                let label = solutionData[rowIndex][j]["label"];
                let startString;
                let endString;
                let start = new Date(this.props.start.getTime());
                let end = new Date(this.props.start.getTime());
                addField(rowIndex * this.props.range + interval[0], this.props.interval, start);
                addField(rowIndex * this.props.range + interval[1], this.props.interval, end);
                if (this.props.timeFormat) {
                    startString = toTimeString(start, "L");
                    endString = toTimeString(end, "L");
                } else {
                    startString = toDateString(start, "L");
                    endString = toDateString(end, "L");
                }
                rows[rowIndex].push(new Event({color: color, borderColor: borderColor, label: label, start: startString, end: endString}, 
                    Math.floor(interval[0] / factor), Math.floor(interval[1] / factor)));
            }
        }

        for (var i = 0; i < this.props.tableRows.length; i += 1) {
            if (rows[i] === undefined) {
                rows[i] = [];
            } else {
                rows[i].sort((a, b) => {
                    return a.start - b.end;
                })
            }
        }

        let hover = undefined;
        if (!this.props.omitIcon) {
            hover = <CheckCircleIcon sx={{color: "success.main"}}/>
            if (messages !== undefined && messages.length > 0) {
                let icon;
                let severities = messages.map(message => message.severity);
                if (severities.includes("error")) {
                    icon = <ErrorIcon sx={{color: "error.main"}}/>
                } else if (severities.includes("warning")) {
                    icon = <WarningIcon sx={{color: "warning.main"}}/>
                } else {
                    icon = <InfoIcon sx={{color: "info.main"}}/>
                }
                hover = <Tooltip arrow title={
                    <div style={{fontSize: '14px', padding: 6}}>
                        {messages.map((message, index) => (
                            <div key={index}>
                                {index > 0 && <hr style={{border: '0.25px solid #ccc', margin: '6px 0'}}/>}
                                {message.content}
                            </div>
                        ))}
                    </div>
                } open={this.state.tooltipOpen || false}>
                    <IconButton onClick={this.toggleTooltip}>{icon}</IconButton>
                </Tooltip>
            }
        }

        let menu = <Paper sx={{ width: 230, maxWidth: '60%', left: this.state.rightClickX, top: this.state.rightClickY, zIndex: 999, position: "absolute"}}>
            <MenuList>
                {this.state.showNames && <MenuItem onClick={this.toggleNames}>
                    <ListItemIcon>
                        <PersonOffIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Hide Names</ListItemText>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {getCtrlSymbol()}A
                    </Typography>
                </MenuItem>}
                {!this.state.showNames && <MenuItem onClick={this.toggleNames}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Show Names</ListItemText>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {getCtrlSymbol()}A
                    </Typography>
                </MenuItem>}
            </MenuList>
        </Paper>

        let unit = this.props.scale / factor;
        let table = this.convertToTable(rows, unit);
        return <div data-testid="displayTable" onContextMenu={this.handleContextMenu}>
            <table className="selection" ref={this.tableRef} width={"100%"}>
                { this.constructTableHeader(unit, hover) }
                <tbody>
                    { table.map((tableRow, i) => {
                        return <tr className="selection" key={i} height={this.props.height} colSpan={unit * (this.props.tableCols.length + 1)}>
                            { this.constructTableRowHeader(i, unit) }
                            { tableRow.filter(([, length]) => length > 0).map(([id, length], j) => {
                                if (id !== undefined) {
                                    return <Tooltip key={j} arrow title={
                                        <div>
                                            {id.label.map((label, index) => (
                                                <div key={index}>{label}</div>
                                            ))}
                                            <div>{id.start} - {id.end}</div>
                                        </div>
                                    }>
                                        <td className="selection" style={{border: `1px double ${id.borderColor}`, background: id.color, color: 'white', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} colSpan={length} >{this.state.showNames && id.label}</td>
                                    </Tooltip>
                                } else {
                                    return <td className="selection" key={j} colSpan={length} ></td>
                                }
                                })}
                        </tr>
                    })}
                </tbody>
            </table>
            {this.state.rightClickX && this.state.rightClickY && menu}
        </div>
    }
}