import React from 'react';
import UnionSchedulerPage from './StaticSchedulerPages/UnionSchedulerPage';
import IntersectionSchedulerPage from './StaticSchedulerPages/IntersectionSchedulerPage';
import SelectionSchedulerPage from './StaticSchedulerPages/SelectionSchedulerPage';
import Home from './HomePage';
import Pricing from './PricingPage';
import Paper from '@mui/material/Paper';
import Explore from './ExplorePage';
import Login from './LoginPage';
import MySchedules from './MySchedulesPage';
import SharePage from './SharePage';
import { AuthProvider } from "./AuthContext"
import PrivateRoute from "./PrivateRoute"
import PlanPage from "./PlanPage"
import Nav from './Nav';
import Footer from './Footer';
import TextPage from './TextPage';
import DynamicSchedulerPageDelegator from './DynamicSchedulerPages/DynamicSchedulerPageDelegator';
import FulfillmentSchedulerPage from './StaticSchedulerPages/FulfillmentSchedulerPage'
import SupportPage from './SupportPage';
import ChatPage from './ChatPage';
import SubmitIssuePage from './SubmitIssuePage';
import { NOT_FOUND_TEXT, NOT_FOUND_SUBTEXT, UNION_SCHEDULER_NAME, INTERSECTION_SCHEDULER_NAME, SELECTION_SCHEDULER_NAME, DISTRIBUTION_SCHEDULER_NAME, FULFILLMENT_SCHEDULER_NAME } from '../utils/Constants';

import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import DistributionSchedulerPage from './StaticSchedulerPages/DistributionSchedulerPage';
import SettingsPage from './SettingsPage';
import { PrivacyPage } from './PrivacyPage';
import StaticContentPage from './StaticContentPage';
import { TermsPage } from './TermsPage';
import { CookiesPage } from './CookiePolicy';

export default function Page(props) {
    return (
        <Paper elevation={0} sx={{height: `calc(100% - ${props.height})`}}>
            <Router>
                <AuthProvider>
                    <Nav height={props.height}/>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/pricing' element={<Pricing />} />
                        <Route path='/explore' element={<Explore />} />
                        <Route path='/support' element={<SupportPage />} />
                        <Route path={`/${UNION_SCHEDULER_NAME.split(" ").join("_")}_scheduler`} element={<UnionSchedulerPage />} />
                        <Route path={`/${INTERSECTION_SCHEDULER_NAME.split(" ").join("_")}_scheduler`} element={<IntersectionSchedulerPage />} />
                        <Route path={`/${SELECTION_SCHEDULER_NAME}_scheduler`} element={<SelectionSchedulerPage />} />
                        <Route path={`/${DISTRIBUTION_SCHEDULER_NAME}_scheduler`} element={<DistributionSchedulerPage/>} />
                        <Route path={`/${FULFILLMENT_SCHEDULER_NAME}_scheduler`} element={<FulfillmentSchedulerPage />} />
                        <Route exact path='/support/chat' element={<PrivateRoute/>}>
                            <Route path='/support/chat' element={<ChatPage />} />
                        </Route>
                        <Route exact path='/support/feedback' element={<PrivateRoute/>}>
                            <Route path='/support/feedback' element={<SubmitIssuePage />} />
                        </Route>
                        <Route exact path='/scheduler/:id' element={<PrivateRoute/>}>
                            <Route path='/scheduler/:id' element={<DynamicSchedulerPageDelegator />} />
                        </Route>
                        <Route path='/scheduler/:id/share' element={<SharePage />} />
                        <Route path='/login' element={<Login />} />
                        <Route exact path='/plan' element={<PrivateRoute/>}>
                            <Route path='/plan' element={<PlanPage />} />
                        </Route>
                        <Route exact path='/settings' element={<PrivateRoute/>}>
                            <Route path='/settings' element={<SettingsPage />} />
                        </Route>
                        <Route exact path='/schedules' element={<PrivateRoute/>}>
                            <Route path='/schedules' element={<MySchedules />} />
                        </Route>
                        <Route path='/terms_of_use' element={<StaticContentPage content={<TermsPage />} />} />
                        <Route path='/privacy_policy' element={<StaticContentPage content={<PrivacyPage />}/>} />
                        <Route path='/cookie_policy' element={<StaticContentPage content={<CookiesPage />}/>} />
                        <Route path="*" element={<TextPage text={NOT_FOUND_TEXT} subtext={NOT_FOUND_SUBTEXT}/>}/>
                    </Routes>
                    <Footer/>
                </AuthProvider>
            </Router>
        </Paper>
    )
}