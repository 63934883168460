const orderList = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const orderMap = [...orderList].reduce((acc, char, i) => {acc[char] = i; return acc;}, {})

export const FIRST_CHAR = orderList[0];
export const SECOND_CHAR = orderList[1];
export const SECOND_LAST_CHAR = orderList[orderList.length - 2];
export const LAST_CHAR = orderList.slice(-1);

export function getOrderInBetween(start, end) {
    start = start || FIRST_CHAR;
    end = end || LAST_CHAR;

    let newOrder = "";
    let length = Math.max(start.length, end.length);
    start += "0".repeat(length - start.length)
    for (let i = 0; i < length; i += 1) {
        if (start[i] === end[i]) {
            newOrder += start[i] || end[i];
        } else {
            let newChar = middleChar(start[i], end[i]);
            if (newChar === start[i] || newChar === end[i] ) {
                if (i === end.length - 1) {
                    newOrder += newChar;
                    i += 1;
                    if (i !== length && start[i] === LAST_CHAR) {
                        while (start[i] === LAST_CHAR) {
                            newOrder += LAST_CHAR
                            i += 1;
                            newChar = start[i];
                        }
                    }

                    if (i === length) {
                        newOrder += orderList[Math.floor((orderList.length - 1) / 2)];
                    } else {
                        newOrder += middleChar(nextChar(start[i]), orderList.slice(-1))
                    }
                } else {
                    newOrder += nextChar(newChar);
                }
            } else {
                newOrder += newChar;
            }
            return newOrder;
        }
    }
    return newOrder + orderList[Math.floor((orderList.length - 1) / 2)];
}

export function nextChar(c) {
    return orderList[orderMap[c] + 1];
}

function getAppendedOrder(lastOrder) {
    if (lastOrder < SECOND_LAST_CHAR) {
        return nextChar(lastOrder[0]);
    } else {
        return getOrderInBetween(lastOrder);
    }
}

function middleChar(startChar, endChar) {
    startChar = startChar || FIRST_CHAR;
    endChar = endChar || LAST_CHAR;
    return orderList[Math.floor((orderMap[startChar] + orderMap[endChar]) / 2)];
}

export function findAppendedOrder(orders) {
    let newOrder;
    if (orders.length === 0) {
        newOrder = SECOND_CHAR;
    } else if (orders[orders.length - 1] >= SECOND_LAST_CHAR) {
        newOrder = getOrderInBetween(orders[orders.length - 1]);
    } else {
        newOrder = nextChar(orders[orders.length - 1][0]);
    }
    return newOrder;
}

export function findInsertedOrder(orders, index) {
    let newOrder;
    if (index === orders.length) {
        newOrder = getAppendedOrder(orders[orders.length - 1]);
    } else {
        if (index === 0) {
            newOrder = getOrderInBetween(undefined, orders[index]);
        } else {
            newOrder = getOrderInBetween(orders[index - 1], orders[index]);
        }
    }
    return newOrder;
}