import React from 'react';
import DynamicScheduleCard from '../../components/DynamicScheduleCard';
import DynamicSlotList from '../../components/DynamicSlotList';
import DynamicSchedulerPage from './DynamicSchedulerPage';
import { generateNcSlotContent } from '../../utils/SlotContentFactory';
import { capitalize } from '../../utils/StringUtils';
import {
    ERROR_SOLUTION_MESSAGE,
    INTERSECTION_SCHEDULER_NAME,
    INVALID_SLOT_DELETE_MESSAGE,
    MAX_SLOT_ADD_MESSAGE,
    SLOTS_PER_PLAN
} from '../../utils/Constants';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {getColor} from "../../utils/ColorMapper";
import {DynamicTabbedMultiDisplayTable} from "../../components/DynamicTabbedMultiDisplayTable";

class DynamicIntersectionSchedulerPage extends DynamicSchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <DynamicScheduleCard key="scheduleCard" icon="/intersection_icon.png"  {...this.scheduleProps()}/>,
            slotList: () => <DynamicSlotList key="slotList" contentFactory={generateNcSlotContent(this.state.time)} {...this.slotProps()}/>,
            displayTable: () => <DynamicTabbedMultiDisplayTable key="displayTable" {...this.displayProps()} />
        }
    }

    openDialog (action, passthroughVars) {
        return () => {
            let limit = SLOTS_PER_PLAN[this.props.id][this.state.planName];
            switch(action) {
                case "add":
                    if (this.slotsRef.current.getSize() >= limit) {
                        this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max ${limit})`});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                case "delete":
                    if (this.state.scheduleActive || this.state.displayActive || this.slotsRef.current.getSize() === 0) {
                        this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
                    } else {
                        super.openDialog(action, passthroughVars)();
                    }
                    break;
                default:
                    super.openDialog(action, passthroughVars)();
            }
        }
    }

    async findSolution(timesMap, intervalsMaps, metadata) {
        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};
        let slotsInfo = metadata["slotsInfo"];
        let slots = intervalsMaps.map((intervalsMap) => { return {"rows": intervalsMap }});
        let input = {"schedule":{"rows": timesMap}, "slots": slots};
        //console.log(timesMap, intervalsMaps);
        let response = await this.callSolver("common_time", input);
        //console.log("response", response);
        if (response === undefined) {
            return [{messages: [{severity: "error", content: ERROR_SOLUTION_MESSAGE}]}];
        }
        let solutionData = {};
        for (let rowIndex of Object.keys(response.schedule.table)) {
            solutionData[rowIndex] = response.schedule.table[rowIndex].map((entry) => {
                let indices = entry.data.indices;
                let level = indices.length / slotsInfo["nameArray"].length * 800 + 100;
                return {"interval": [entry.start, entry.end], "borderColor": getColor("green", level + 100), "color": getColor("green", level), "label": indices.map(index => slotsInfo["nameArray"][index])};
            });
        }
        return [{table: {solutionData: solutionData}}];
    }

    componentDidMount() {
        super.componentDidMount();
        document.title = `${capitalize(INTERSECTION_SCHEDULER_NAME)} scheduler | Snug Scheduler`;
    }
}

export default withErrorBoundary(DynamicIntersectionSchedulerPage);