//intervals: [ { 0: [[a, b], [c, d]], 5: ... } ]
function decompress(intervalsMaps, maxSpan, wrap) {
    let intervals = [];
    let maxTime = 0;
    for (let i = 0; i < intervalsMaps.length; i += 1) {
        let intervalsMap = intervalsMaps[i];
        let arr = [];
        for (let key in intervalsMap) {
            let intervals = intervalsMap[key];
            for (let j = 0; j < intervals.length; j += 1) { 
                let interval = intervals[j];
                let factor = key * maxSpan;
                arr.push([factor + interval[0], factor + interval[1]]);
                maxTime = Math.max(maxTime, factor + interval[1]);
            }
        }
        arr.sort((a, b) => {
            if (a[0] !== b[0]) {
                return a[0] - b[0];
            } else {
                return a[1] - b[1];
            }
        });

        if (wrap) {
            for (let i = arr.length - 2; i >= 0; i -= 1) {
                if (arr[i][1] % maxSpan === 0) {
                    if (arr[i + 1][0] === arr[i][1]) {
                        arr[i][1] = arr[i + 1][1];
                        arr.splice(i + 1, 1);
                    }
                }
            }
        }
        intervals.push(arr);
    }
    return [intervals, maxTime];
}

function compress(times, lengths, maxSpan, wrap) {
    if (times.length === 0) {
        return undefined;
    }
    let intervalsMaps = [];
    for (let i = 0; i < times.length; i += 1) {
        if (times[i] !== undefined) {
            let key = Math.floor(times[i] / maxSpan);
            let value = times[i] % maxSpan;
            let intervalsMap = {};
            if (wrap) {
                let currValue = value;
                let endValue = value + lengths[i];
                let offset = 0;
                while (endValue > currValue) {
                    if (intervalsMap[key + offset] === undefined) {
                        intervalsMap[key + offset] = [];
                    }
                    let nextValue = Math.min(currValue + maxSpan - currValue % maxSpan, endValue);
                    intervalsMap[key + offset].push([currValue % maxSpan, mod(nextValue - 1, maxSpan) + 1]);
                    currValue = nextValue;
                    offset += 1;
                }
            } else {
                intervalsMap[key] = [[value, value + lengths[i]]];
            }
            intervalsMaps[i] = intervalsMap;
        }
    }
    return intervalsMaps;
}

function intersect(a, b) {
    if (a[0] > b[0] || (a[0] === b[0] && a[1] > b[1])) {
        let temp = a;
        a = b;
        b = temp;
    }
    if (b[1] <= a[1]) { // [ < > ]
        return b;
    } else if (b[0] < a[1]) { // [ < ] >
        return [b[0], a[1]];
    } else { // [ ] < >
        return undefined;
    }
}

function intersection(timesMap, intervalsMaps) {
    let intersectedIntervalsMaps = [];
    for (let i = 0; i < intervalsMaps.length; i += 1) {
        let intersectedIntervalsMap = {};
        for (let key in intervalsMaps[i]) {
            let times = timesMap[key];
            if (times !== undefined) {
                let intervals = intervalsMaps[i][key];
                let timesIndex = 0;
                let intervalsIndex = 0;
                while (timesIndex < times.length && intervalsIndex < intervals.length) {
                    let intersection = intersect(times[timesIndex], intervals[intervalsIndex]);
                    if (intersection !== undefined) {
                        if (intersectedIntervalsMap[key] === undefined) {
                            intersectedIntervalsMap[key] = [];
                        }
                        intersectedIntervalsMap[key].push(intersection);
                    }
                    if (times[timesIndex][1] <= intervals[intervalsIndex][1]) {
                        timesIndex += 1;
                    } else {
                        intervalsIndex += 1;
                    }
                }
            }
        }
        intersectedIntervalsMaps.push(intersectedIntervalsMap);
    }
    return intersectedIntervalsMaps;
}

export default function findSolution(timesMap, intervalsMaps, lengths, maxSpan, wrap) {
    let [intervals, maxTime] = decompress(intersection(timesMap, intervalsMaps), maxSpan, wrap);
    let set = new Array(intervals.length);
    for (let i = 0; i < set.length; i += 1) {
        set[i] = true;
    }
    let cache = {};
    findSolutionHelper(intervals, lengths, set, cache, maxTime + 1);

    let [order, missing] = extractOrder(set, cache);

    let times = [];
    let currTime = 0;
    for (let i = 0; i < order.length; i += 1) {
        let index = order[i];
        let schedule = intervals[index];
        for (let j = 0; j < schedule.length; j += 1) {
            if (schedule[j][1] - schedule[j][0] >= lengths[index] && schedule[j][1] >= currTime + lengths[index]) {
                currTime = Math.max(schedule[j][0], currTime) + lengths[index];
                times[index] = currTime - lengths[index];
                break;
            }
        }
    }

    return {result: compress(times, lengths, maxSpan, wrap), missing: missing};
}

function extractOrder(set, cache) {
    let setQueue = [];
    setQueue.push(set);
    while(setQueue.length !== 0) {
        let set = setQueue.shift();
        let setHash = hash(set);
        if (setHash in cache && cache[setHash][1] !== -1) {
            let missing = [];
            for (let i = 0; i < set.length; i += 1) {
                if (!set[i]) {
                    missing.push(i);
                }
            }

            let order = [];
            while (setHash in cache && cache[setHash][1] !== -1) {
                let index = cache[setHash][1];
                order.unshift(index);
                set[index] = false;
                setHash = hash(set);
            }
            return [order, missing];
        }
        if (!isEmpty(set)) {
            for (let i = 0; i < set.length; i += 1) {
                if (set[i]) {
                    let newSet = [...set];
                    newSet[i] = false;
                    setQueue.push(newSet);
                }
            }
        }
    }
    return [[], [...Array(set.length).keys()]];
}

function findSolutionHelper(intervals, lengths, set, cache, maxTime) {
    if (isEmpty(set)) {
        return 0;
    }

    let setHash = hash(set);
    if (!(setHash in cache)) {
        let minTime = maxTime;
        let mIndex = -1;
        for (var i = 0; i < set.length; i += 1) {
            if (set[i]) {
                set[i] = false;
                let solutionTime = findSolutionHelper(intervals, lengths, set, cache, maxTime)
                set[i] = true;
                for (var j = 0; j < intervals[i].length; j += 1) {
                    let time = Math.max(intervals[i][j][0], solutionTime);
                    if (intervals[i][j][1] >= time + lengths[i]) {
                        if (time + lengths[i] < minTime) {
                            minTime = time + lengths[i];
                            mIndex = i;
                        }
                        break;
                    }
                }
            }
        }
        cache[setHash] = [minTime, mIndex];
    }
    return cache[setHash][0];
}

function isEmpty(set) {
    for (var i = 0; i < set.length; i += 1) {
        if (set[i]) {
            return false;
        }
    }
    return true;
}

function hash(set) {
    let hash = 0;
    let factor = 1;
    for (var i = 0; i < set.length; i += 1) {
        hash += factor * (set[i] ? 1 : 0);
        factor *= 2;
    }
    return hash;
}

function mod(num, modulo) {
    return ((num % modulo) + modulo) % modulo;
}