import React from "react";
import Box from "@mui/material/Box";
import {Draggable} from "react-beautiful-dnd";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

export class DisplayTabs extends React.Component {

    getItemStyle = (isDragging) => {
        return {
            border: isDragging || this.props.active ? 1 : 0,
            borderBottom: isDragging || 0,
            borderColor: isDragging ? "black" : "lightgrey",
            borderRadius: `7px 7px ${isDragging ? "7px 7px" : "0px 0px"}`
        }
    };

    onDeleteClick = (event) => {
        event.stopPropagation();
        this.props.deleteAction();
    }

    render() {
        return <Draggable key={this.props.id} draggableId={this.props.id} index={this.props.index}>
            {(provided, snapshot) => (
                <Box width={`${this.props.width}%`} maxWidth="200px" {...provided.draggableProps}>
                    <Box ref={provided.innerRef} {...provided.dragHandleProps}
                         display="flex"
                         overflow={"hidden"}
                         style={{
                             justifyContent: "space-between",
                         }}
                         onMouseDown={this.props.onPressAction}
                         backgroundColor={this.props.active && "white"}
                         {... this.getItemStyle(snapshot.isDragging)}>
                        <Typography sx={{flexShrink: 1}} p={1} pl={1.5} textOverflow='ellipsis'
                                    noWrap>{this.props.name}</Typography>
                        <Stack direction="row" sx={{flexShrink: 0}} alignItems="center">
                            <IconButton sx={{p: 2, width: '20px', height: '20px'}} onClick={this.onDeleteClick}>
                                <CloseIcon fontSize="small" onMouseDown={(event) => event.stopPropagation()}/>
                            </IconButton>
                            {this.props.divider && <Divider orientation="vertical" sx={{margin: '0.5rem 0', borderWidth: 1.5}} flexItem color={"white"}/>}
                        </Stack>
                    </Box>
                </Box>
            )}
        </Draggable>
    }
}