import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Page from './pages/Page';

const root = ReactDOM.createRoot(document.getElementById('root'));
const height = "60px"
root.render(
  <React.Fragment>
    <Page height={height}/>
  </React.Fragment>
);

//<Table numRows={7} numCols={20} width={100} height={50} />

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
