import React from "react";
import Typography from '@mui/material/Typography';
import Event from "./Event"

export class Table extends React.Component {

  getWidth() {
    return this.tableRef.current.clientWidth / (this.props.tableCols.length + 1);
  }

  getTextVariant(boundaries, textMap) {
    let width = this.getWidth();
    if (width < boundaries[0]) {
      return textMap["S"];
    } else if (width < boundaries[1]) {
      return textMap["M"];
    } else {
      return textMap["L"];
    }
  }

	constructTableHeader(factor=1, cornerContent=undefined) {
    return <thead>
      <tr className="selection" height={`${this.props.height}px`} colSpan={factor * (this.props.tableCols.length + 1)}>
          <th className="selection" colSpan={factor}>{cornerContent}</th>
          {this.props.tableCols.map((e, i) => {
            let textVariant = this.tableRef.current ? this.getTextVariant([50, 90], e) : e["M"];
            return <th className="selection" key={i} style={{ userSelect: "none", ...textVariant.style }} colSpan={factor}>{textVariant.content}</th>
          })}
      </tr>
    </thead>
  }

	constructTableRowHeader(index, factor=1) {
    let textVariant = this.tableRef.current ? this.getTextVariant([50, 120], this.props.tableRows[index]) : this.props.tableRows[index]["M"];
    return <td className="rowHeader" colSpan={factor} style={{ userSelect: "none" }} >
        <Typography className="rowText" noWrap variant="body1" sx={{textAlign:"center", textOverflow: "clip", userSelect: "none", ...textVariant.style}}>
          {textVariant.content}
        </Typography>
    </td>
	}

	convertToTable(eventData, factor=1) {
    let table = [];
    for (var i = 0; i < this.props.tableRows.length; i += 1) {
      let arr = [];
      let paddedData = eventData[i].slice();
      let maxLength = factor * this.props.tableCols.length;
      paddedData = paddedData.filter((event) => event.start < maxLength);
      paddedData.splice(0, 0, new Event(undefined, 0, 0));
      paddedData.push(new Event(undefined, maxLength, maxLength));
      for (var j = 1; j < paddedData.length; j += 1) {
        arr.push([undefined, paddedData[j].start - paddedData[j - 1].end]);
        arr.push([paddedData[j].id, Math.min(maxLength, paddedData[j].end) - paddedData[j].start, paddedData[j].magnitude]);
      }
      arr = arr.filter(([, length]) => length > 0);
      table.push(arr);
    }
    return table;
  }

  componentDidMount() {
    let currentColumnNames = this.tableRef.current.querySelectorAll('th');
    for (let i = 1; i < currentColumnNames.length; i += 1) {
      let textVariant = this.getTextVariant([50, 90], this.props.tableCols[i - 1]);
      currentColumnNames[i].textContent = textVariant.content;
      if (textVariant.style) {
        for (const [key, value] of Object.entries(textVariant.style)) {
            currentColumnNames[i].style[key] = value;
        }
      }
    }

    let currentRowNames = this.tableRef.current.querySelectorAll('.rowText');
    for (let i = 0; i < currentRowNames.length; i += 1) {
      let textVariant = this.getTextVariant([50, 120], this.props.tableRows[i]);
      currentRowNames[i].textContent = textVariant.content;
      if (textVariant.style) {
        for (const [key, value] of Object.entries(textVariant.style)) {
            currentRowNames[i].style[key] = value;
        }
      }
    }
  }
}