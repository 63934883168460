import React from "react";
import { getColor } from "../utils/ColorMapper";
import { ActionTable } from "./ActionTable";
import Event from "./Event";
import MenuItem from '@mui/material/MenuItem';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import lodash from "lodash";
import { getCtrlSymbol, isMac } from "../utils/PlatformUtils";

// *Not for instantiated use
export class MotionTable extends ActionTable {
	constructor(props) {
		super(props);
		this.count = 0;
		this.arr = this.getInternalArr(this.props.data, true);
		this.state.selectAll = false;
		this.state.data = this.arr;
		this.state.history = [lodash.cloneDeep(this.arr)];
		this.state.historyIndex = 0;
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	moveMouseInRow(id, mousePos, y) {
		let i = 0;
		while (i < this.arr.length) {
			let j = this.arr[i].findIndex(event => event.id === id)
			if (j === -1) {
				i += 1;
				continue;
			}
			let e = this.arr[i][j];
			let deltaX = Math.sign(mousePos - this.initialMousePosition) * this.getIndexFromX(Math.abs((mousePos - this.initialMousePosition)));
			if (deltaX !== 0 || y !== i) {
				this.arr[i].splice(j, 1) //remove item
				let newEvent = new Event(e.id, e.start + deltaX, e.end + deltaX);
				if (this.orderedEventAdd(this.arr[y], newEvent)) {
					this.initialMousePosition += deltaX * this.getWidth();
					this.setState({data: this.arr});
				} else {
					this.arr[i].splice(j, 0, e)
				}
			}
			return;
		}
	}

	expandMouseEventRight(id) {
		return (event) => {
			let mousePos = this.getMouseX(event);
			let i = 0;
			while (i < this.arr.length) {
				let j = this.arr[i].findIndex(event => event.id === id);
				if (j === -1) {
					i += 1;
					continue;
				}
				let event = this.arr[i][j];
				let newEnd = Math.floor(mousePos / this.getWidth() - 0.5) + 1;
				if (newEnd <= this.props.tableCols.length && event.end !== newEnd) {
					if (newEnd > event.start && (j >= this.arr[i].length - 1 || newEnd <= this.arr[i][j + 1].start)) {
						event.end = newEnd;
						this.setState({data: this.arr});
					}
				}
				return;
			}
		}
	}

	expandMouseEventLeft(id) {
		return (event) => {
			let mousePos = this.getMouseX(event);
			let i = 0;
			while (i < this.arr.length) {
				let j = this.arr[i].findIndex(event => event.id === id);
				if (j === -1) {
					i += 1;
					continue;
				}
				let event = this.arr[i][j];
				let newStart = Math.ceil(mousePos / this.getWidth() + 0.5) - 1;
				if (newStart >= 0 && event.start !== newStart) {
					if (newStart < event.end && (j === 0 || newStart >= this.arr[i][j - 1].end)) {
						event.start = newStart;
						this.setState({data: this.arr});
					}
				}
				return;
			}
		}
	}

	deleteMouseEvent(id) {
		return () => {
			let i = 0;
			while (i < this.arr.length) {
				let j = this.arr[i].findIndex(event => event.id === id);
				if (j === -1) {
					i += 1;
					continue;
				}
				this.arr[i].splice(j, 1); //remove item
				this.setState({data: this.arr});
				return;
			}
		}
	}

	orderedEventAdd(eventArray, event) {
		if (event.start < 0 || event.end > this.props.tableCols.length) {
			return false;
		}

		let i = 0;
		while (i <= eventArray.length) {
			if (i === eventArray.length || eventArray[i].start >= event.end) {
				if (i === 0 || eventArray[i - 1].end <= event.start) {
					eventArray.splice(i, 0, event);
					return true;
				} else {
					return false;
				}
			}
			i += 1;
		}
		return false;
	}

	dragCreate(event, immutable) {
		let start = this.getIndexFromX(this.initialMousePosition);
		let end = this.getIndexFromX(this.getMouseX(event));
		if (start > end) {
			[start, end] = [end, start];
		}
		end += 1;

		if (start !== end) {
			let i = this.getIndexFromY(this.getMouseY(event));
			let table = this.arr;
			let count = immutable ? this.count : this.count++;
			if (immutable) {
				table = [...this.arr]
				table[i] =  [...table[i]];
			}

			this.orderedEventAdd(table[i], new Event(count, start, end));
			this.setState({data: table})
		} else {
			this.setState({data: this.arr})
		}
	}

	render(eventFactory) {
		let table;
		if (this.state.selectAll) {
			let convertedData = this.convertToTable([...Array(this.props.tableRows.length)].map(() => Array(0)));
			table = <table className="selection" ref={this.tableRef} width={"100%"}>
				{ this.constructTableHeader() }
				<tbody>
					{convertedData.map((tableRow, i) => {
						return <tr className="selection" key={i} height={this.props.height} colSpan={this.props.tableCols.length + 1}>
							{ this.constructTableRowHeader(i) }
							{tableRow.map(([, length], j) => {
								return <td className="selection" key={j} style={{background: getColor(this.props.color, 200)}} colSpan={length} ></td>
							})}
						</tr>
					})}
				</tbody>
			</table>
		} else {
			this.state.data.splice(this.props.tableRows.length, this.state.data.length - this.props.tableRows.length);
			for (let i = this.state.data.length; i < this.props.tableRows.length; i += 1) {
				this.state.data.push([]);
			}

			let convertedData = this.convertToTable(this.state.data);
			table = <table className="selection" ref={this.tableRef} width={"100%"}
				{...this.tableActions()} >
				{ this.constructTableHeader() }
				<tbody>
				{convertedData.map((tableRow, i) => {
					let cumsum = 0;
					return <tr className="selection" key={i} height={this.props.height} colSpan={this.props.tableCols.length + 1}>
						{ this.constructTableRowHeader(i) }
						{tableRow.map(([id, length], j) => {
							cumsum += length;
							if (id !== undefined) {
								return eventFactory(id, length, j, i, cumsum);
							} else {
								return <td className="selection" key={j} colSpan={length}></td>
							}
						})}
					</tr>
				})}
				</tbody>
			</table>
		}
		return super.render(table);
	}

	handleKeyDown = (event) => {
		super.handleKeyDown(event);
		const ctrlKey = isMac() ? event.metaKey : event.ctrlKey;

		if (ctrlKey && !event.shiftKey && (event.key === 'a' || event.key === 'A')) {
			event.preventDefault();
			event.stopPropagation();
			this.setState({selectAll: !this.state.selectAll});
		}
	}

	additionalMenuItems() {
		return [<MenuItem key={"selectAll"} onClick={() => this.setState({selectAll: !this.state.selectAll})}>
			<ListItemIcon>
				{!this.state.selectAll ? <SelectAllIcon fontSize="small" /> : <DeselectIcon fontSize="small" />}
			</ListItemIcon>
			<ListItemText>{!this.state.selectAll ? "Select all" : "Deselect All" }</ListItemText>
			<Typography variant="body2" sx={{ color: 'text.secondary' }}>
				{getCtrlSymbol()}A
			</Typography>
		</MenuItem>];
	}

	returnData = () => {
		let data = [];
		for (var i = 0; i < this.arr.length; i += 1) {
			let row = [];
			for (var j = 0; j < this.arr[i].length; j += 1) {
				if (row.length > 0 && row[row.length - 1][1] === this.arr[i][j].start) {
					row[row.length - 1][1] = this.arr[i][j].end;
				} else {
					row.push([this.arr[i][j].start, this.arr[i][j].end])
				}
			}
			data.push(row)
		}
		return [data, this.state.selectAll, this.state.clipboard];
	}

	getInternalArr(data, retain) {
		if (data === undefined || !retain) {
			data = [...Array(this.props.tableRows.length)].map(() => []);
		}
		let arr = new Array(this.props.tableRows.length)
		for (let i = 0; i < arr.length; i += 1) {
			arr[i] = new Array(0);
			if (data[i] === undefined) {
				continue;
			}
			for (let j = 0; j < data[i].length; j += 1) {
				arr[i].push(new Event(this.count++, data[i][j][0], data[i][j][1]));
			}
		}
		return arr;
	}

	loadData = (data, selectAll, retain, clipboard, doNotResetCache) => {
		this.arr = this.getInternalArr(data, retain);
		if (!doNotResetCache) {
			this.setState({history: [lodash.cloneDeep(this.arr)], historyIndex: 0});
		} else {
			this.cacheData(this.arr);
		}
		this.setState({data: this.arr, selectAll: selectAll, clipboard: clipboard})
	}
}