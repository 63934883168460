import React from "react";
import styled from "styled-components"
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import { Typography } from "@mui/material";
import { findNextColor } from "../utils/ColorMapper";


const Divider = styled.div`
    padding: 8px;
`;

export default class SlotList extends React.Component {
    constructor(props) {
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.slotPropertyChange = this.slotPropertyChange.bind(this);
        this.state = {orderToSlotIndex: []}
        this.slotRefs = {};
        this.dataAttributes = {};
    }

    slotPropertyChange(slotId) {
        return (key, value) => {
            if (key === "expand" && value === true) {
                for (let otherId in this.slotRefs) {
                    if (otherId !== slotId.toString()) {
                        this.slotRefs[otherId].current.setExpanded(false);
                    }
                }
            }
            if (slotId === this.state.activeSlotId) {
                if (key === "color") {
                    this.props.propChangeAction({"tableColor": value});
                }
            }
        }
    }

    onDragEnd(result) {
        const { destination, source, } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const order = this.state.orderToSlotIndex;
        order.splice(destination.index, 0, order.splice(source.index, 1)[0])
        this.setState({orderToSlotIndex: order})
    }

    slotClickAction(slotId) {
        return (attributes) => {
            if (!this.props.selected || this.state.activeSlotId !== slotId) {
                this.setState({activeSlotId: slotId})
                this.props.slotClickAction(attributes);
            }
        }
    }

    getAuxContentList() {
        return [];
    }
    
    render() {
        if (this.state.orderToSlotIndex.length === 0) {
            return <Card sx={{width: "100%", height: "100%"}}>
				<CardActionArea sx={{width: "100%", height: "100%"}} onClick={this.props.openDialog && this.props.openDialog("add", {"slotsAdd": true})}>
                    <Typography align="center">Slot list is empty</Typography>
                </CardActionArea>
            </Card>
        }
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId={"slotListDroppable"}>
                    {(provided) => (
                        <Divider ref={provided.innerRef} {...provided.droppableProps}>
                            {this.state.orderToSlotIndex.map((slotNumber, index) => {
                                let contentFactoryOutput = this.props.contentFactory(this.dataAttributes[slotNumber]);
                                return React.cloneElement(this.Slot, {ref: this.slotRefs[slotNumber], key: slotNumber, 
                                    scheduleId: this.props.scheduleId, id: slotNumber.toString(), slotId: slotNumber, 
                                    index: index, name: this.dataAttributes[slotNumber].name, color: this.dataAttributes[slotNumber].color, 
                                    slotClickAction: this.slotClickAction(slotNumber), propChangeAction: this.slotPropertyChange(slotNumber), 
                                    timeFormat: this.props.timeFormat, selected: this.props.selected && this.state.activeSlotId === slotNumber, 
                                    subtitle: contentFactoryOutput.subtitle, attributeContentList: contentFactoryOutput.content, 
                                    auxContentList: this.getAuxContentList(slotNumber)})
                            })}
                            {provided.placeholder}
                        </Divider>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    getDefaultAttributesContent() {
        let defaultAttributes = this.state.orderToSlotIndex.length > 0 ? {...this.dataAttributes[this.state.orderToSlotIndex.slice(-1)]}
            : {color: this.props.color || "blue", length: this.props.timeFormat ? 30 : 1, prefTotal: this.props.timeFormat ? 30 : 1};
        if (this.state.orderToSlotIndex.length > 0) {
            if (this.props.rotateColors) {
                defaultAttributes["color"] = findNextColor(defaultAttributes["color"]);
            }
        }
        const existingNames = new Set(Object.values(this.slotRefs).map(slotRef => slotRef.current.getSlotInfo()?.name).filter(name => name !== undefined));
        defaultAttributes["name"] = "Untitled Slot";
        let index = 0;
        while (existingNames.has(defaultAttributes["name"])) {
            defaultAttributes["name"] = `Untitled Slot (${index + 1})`;
            index += 1;
        }
        return [this.props.contentFactory(defaultAttributes).content, this.props.contentFactory(defaultAttributes).attributes];
    }

    addSlot(attributes, newSlotKey) {
        this.dataAttributes[newSlotKey] = attributes;
        let newOrder = [...this.state.orderToSlotIndex];
        newOrder.push(newSlotKey);
        this.slotRefs[newSlotKey] = React.createRef();
        this.setState({orderToSlotIndex: newOrder}, 
            () => this.switchSlot(newSlotKey));
    }

    switchSlot(key) {
        this.slotClickAction(key)({color: this.getSlotColor(key), data: this.getScheduleData(key)});
    }

    deleteActiveSlot() {
        let slotId = this.state.activeSlotId;
        if (!this.props.selected || slotId === undefined) {
            return undefined;
        }
        let newOrder = [...this.state.orderToSlotIndex];
        let i = 0;
        while (i < newOrder.length) {
            if (newOrder[i] === slotId) {
                newOrder.splice(i, 1);
                break;
            }
            i += 1;
        }

        delete this.slotRefs[slotId];
        delete this.dataAttributes[slotId];

        let nextId;
        if (i < newOrder.length) {
            nextId = newOrder[i];
        } else if (i > 0 && i === newOrder.length) {
            nextId = newOrder[i - 1];
        }
        this.setState({activeSlotId: nextId, orderToSlotIndex: newOrder}, () => {
            if (nextId !== undefined) {
                this.props.dataChangeAction(this.getScheduleData());
                this.props.propChangeAction({"tableColor": this.getSlotColor(nextId)});
            }
        });

        return nextId;
    }

    exportAllData(timeFormat) {
        let scheduleDatas = [];
        let scheduleStartDays = [];
        let scheduleEndDays = [];
        let scheduleStartTimes = [];
        let scheduleEndTimes = [];
        let selectAlls = [];
        this.state.orderToSlotIndex.forEach(slotNumber => {
            let [scheduleData, scheduleStartDay, scheduleEndDay, scheduleStartTime, scheduleEndTime, time, selectAll] = this.getScheduleData(slotNumber);
            if (time !== timeFormat) {
                scheduleDatas.push(undefined);
            } else {
                scheduleDatas.push(scheduleData);
            }
            scheduleStartDays.push(scheduleStartDay);
            scheduleEndDays.push(scheduleEndDay);
            scheduleStartTimes.push(scheduleStartTime);
            scheduleEndTimes.push(scheduleEndTime);
            selectAlls.push(selectAll);
        });
        return [scheduleDatas, scheduleStartDays, scheduleEndDays, scheduleStartTimes, scheduleEndTimes, selectAlls];
    }

    getSlotsInfo() {
        let slotsInfo = {};
        this.state.orderToSlotIndex.forEach(slotNumber => {
            let slotInfo = this.slotRefs[slotNumber].current.getSlotInfo();
            for (let key in slotInfo) {
                let arrayKey = key + "Array";
                if (slotsInfo[arrayKey] === undefined) {
                    slotsInfo[arrayKey] = [];
                }
                slotsInfo[arrayKey].push(slotInfo[key]);
            }
        });
        return slotsInfo;
    }


    getScheduleData = (slotId=this.state.activeSlotId) => {
        if (this.slotRefs[slotId] === undefined) {
            return [undefined, undefined, undefined, undefined, undefined, true, undefined];
        }
        return this.slotRefs[slotId].current.getScheduleData();

    }

    getSlotColor(slotId=this.state.activeSlotId) {
        if (this.slotRefs[slotId] === undefined) {
            return "grey"
        }
        return this.slotRefs[slotId].current.getSlotInfo().color;
    }

    saveDataToActive(data, timeFormat, scheduleStartDay, scheduleEndDay, scheduleStartTime, scheduleEndTime, selectAll) {
        if (this.slotRefs[this.state.activeSlotId] !== undefined) { //may occur if last slot was just deleted
            this.slotRefs[this.state.activeSlotId].current.saveData(data, timeFormat, scheduleStartDay, scheduleEndDay, scheduleStartTime, scheduleEndTime, selectAll);
        }
    }

    getSize() {
		return this.state.orderToSlotIndex.length;
	}

    //Only used for slotlistlist (todo: refactor as a general mass update method)
    resetColors(color) {
        for (let key in this.slotRefs) {
            this.slotRefs[key].current.setColor(color);
            this.dataAttributes[key].color = color;
        }
    }

    toString(startDay, endDay, startTime, endTime, startDate, timeFormat) {
        return `${Object.values(this.slotRefs).map(slotRef => slotRef.current.toString(startDay, endDay, startTime, endTime, startDate, timeFormat)).join("\n\n")}`;
    }
}