import React from "react";
import { getColor } from "../utils/ColorMapper";
import { MotionTable } from "./MotionTable";


export class TouchTable extends MotionTable {
  initFn(event) {
    this.initialMousePosition = this.getMouseX(event);
    this.pageY = event.changedTouches[0].pageY;
    this.initialMouseY = this.getMouseY(event);
    this.slotOffset = this.initialMousePosition - (event.target.getBoundingClientRect().x -  event.target.parentElement.getBoundingClientRect().x - this.getWidth());
  }

  getMouseY(event) {
    let offset = -this.tableRef.current.offsetTop - window.pageYOffset + this.props.parentRef.current.scrollTop;
    if (event.touches !== undefined && event.touches[0] !==  undefined) {
        return event.touches[0].pageY + offset;
    } else if (event.changedTouches !== undefined && event.changedTouches[0] !== undefined) {
        return event.changedTouches[0].pageY + offset;
    }
    return null;
  }

  getMouseX(event) {
    let offset = -this.tableRef.current.offsetLeft - window.pageXOffset + this.props.parentRef.current.scrollLeft;
    if (event.touches !== undefined && event.touches[0] !==  undefined) {
        return event.touches[0].pageX + offset - this.getWidth();
    }  else if (event.changedTouches !== undefined && event.changedTouches[0] !== undefined) {
        return event.changedTouches[0].pageX + offset - this.getWidth();
    }
    return null;
  }

  onTouchUp = (event) => {
    this.onMouseUp(event);
  }

  touchInitFn = (event) => {
    this.initialRow = this.getIndexFromY(this.getMouseY(event));
    this.initFn(event);
  }

  onTableTouch(event) {
    if (Math.abs(event.changedTouches[0].pageY - this.pageY) < this.props.height / 3) {
      this.dragCreate(event, false);
    } else {
      this.setState({data: this.arr});
    }
  }

  onTableSwipe(event) {
    if (Math.abs(event.changedTouches[0].pageY  - this.pageY) < this.props.height / 3) {
      this.dragCreate(event, true);
    } else {
      this.setState({data: this.arr});
    }
  }

  tableActions() {
    return {onTouchStart: this.onMouseDown(this.touchInitFn, this.onTableSwipe, this.onTableTouch, this.onTableTouch), onTouchMove: this.onMouseMove, onTouchEnd: this.onTouchUp };
  }

  slotActions(id, length) {
    let moveFn = (event) => {
      let mousePercent = (this.slotOffset) / (length * this.getWidth());
      if (mousePercent < 0.2) {
        this.expandMouseEventLeft(id)(event);
      } else if (mousePercent > 0.8) {
        this.expandMouseEventRight(id)(event);
      } else {
        this.moveMouseInRow(id, this.getMouseX(event), this.getIndexFromY(this.initialMouseY));
      }
    }
    return {onTouchStart: this.onMouseDown(this.touchInitFn, moveFn, undefined, this.deleteMouseEvent(id))};
  }

  render() {
    let eventFactory = (id, length, j) => {
      return <td key={j} id={id} style={{background: getColor(this.props.color, 500)}} colSpan={length} {...this.slotActions(id, length)}/>
    };
    return super.render(eventFactory);
  }
}