import React from "react";
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DeleteIcon from '@mui/icons-material/Delete';
import TableChartIcon from '@mui/icons-material/TableChart';
import AddIcon from '@mui/icons-material/Add' ;
import { Tooltip } from "@mui/material";
import { getCtrlSymbol } from "../utils/PlatformUtils";

export default class ActionsCard extends React.Component {
    render() {
        return <Paper elevation={0} variant="outlined" className="menuItem">
        <BottomNavigation
          showLabels
          value={this.props.displayActive ? 1 : undefined}
          onChange={(event, newValue) => {
            switch(newValue) {
              case 0:
                this.props.primaryAction();
                break;
              case 1:
                this.props.secondaryAction();
                break;
              case 2:
                this.props.tertiaryAction();
                break;
              default: 
                break;
            }
          }}
        >
          <BottomNavigationAction label="Delete" icon={
            <Tooltip title={`Delete the currently active slot (${getCtrlSymbol()}D)`}><DeleteIcon /></Tooltip>
          } sx={{minWidth: 66}}/>
          <BottomNavigationAction label="Schedule" icon={
            <Tooltip title="Switch between solution and input tables"><TableChartIcon /></Tooltip>
          } sx={{minWidth: 66}}/>
          <BottomNavigationAction label="Add" icon={
            <Tooltip title={`Add a new slot (${getCtrlSymbol()}M)`}><AddIcon /></Tooltip>
          } sx={{minWidth: 66}}/>
        </BottomNavigation>
      </Paper>
    }
}