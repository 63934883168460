import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { lightBlue } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { auth } from "../firebase"
import { signOut } from "firebase/auth";
import { useAuth } from "./AuthContext"
import { useNavigate, useLocation } from "react-router-dom";
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import { EXPLORE_TITLE, LOGIN_TITLE, PRICING_TITLE, SUPPORT_TITLE } from '../utils/Constants';
import { log } from '../utils/AnalyticsUtils';
import { capitalize } from '../utils/StringUtils';

const pages = [EXPLORE_TITLE, PRICING_TITLE, SUPPORT_TITLE];
const pageLinks = ["/explore", "/pricing", "/support"];
const settings = ['My Schedules', 'Account settings', 'Logout'];


const theme = createTheme({
	palette: {
		primary: {
			main: '#FFFFFF',
			// light: will be calculated from palette.primary.main,
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: lightBlue,
	},
	typography: {
		button: {
			fontWeight: 600,
		},
	},
});

export default function Nav(props) {
	const navigate = useNavigate();
	const location = useLocation()

	const logout = () => {
		log("link_click", {name: "logout", metadata: "nav"});
		signOut(auth).then(() => {
			navigate("/");
		}).catch((error) => {
			console.error(error);
		});
	}

	const settingsActions = [
		() => {
			log("link_click", {name: "Nav - My Schedules", metadata: "nav"});
			navigate("/schedules")
		}, 
		() => {
			log("link_click", {name: "Nav - Settings", metadata: "nav"});
			navigate("/settings")
		}, logout]


	const {currentUser} = useAuth();
	
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [anchorElUser, setAnchorElUser] = useState(null);
	const userdata = currentUser ? {name: currentUser.displayName, email: currentUser.email, picture_url: currentUser.photoURL} : {};

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleSettingsSelection = (name, action) => {
		return () => {
			logLinkOnClick(name)();
			action();
			handleCloseUserMenu();
		}
	}

	function logLinkOnClick(name) {
        return () => {
            log('link_click', {name: capitalize(name), metadata: "nav", origin: location.pathname});
        }
    }

	return( <ThemeProvider theme={theme}>
			<AppBar position="static" elevation={0} sx={{ height: props.height }}>
				<Container maxWidth="xl">
					<Toolbar disableGutters>
						<Link onClick={logLinkOnClick("Home")} href="/">
							<Icon sx={{display: { xs: 'none', sm: 'flex' }, mr: {xs: 0, sm: 2}, width: 75, height: 30 }} onClick={() => navigate("/")}>
								<img src={"/logo_filled_text.png"}/>
							</Icon>
						</Link>

						<Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: 'block', md: 'none' },
									mt:6
								}}
							>
								{pages.map((page, index) => (
									<MenuItem key={page} onClick={handleCloseNavMenu}>
										<Button onClick={logLinkOnClick(pageLinks[index].slice(1))} textalign="center" sx={{color: "inherit"}} href={pageLinks[index]}>{page}</Button>
									</MenuItem>
								))}
							</Menu>
						</Box>
						<Link onClick={logLinkOnClick("home")} href="/">
							<Icon sx={{display: { xs: 'flex', sm: 'none' }, mr: 0, width: 75, height: 30 }}>
								<img src={"/logo_filled_text.png"}/>
							</Icon>
						</Link>
						<Typography
							variant="h5"
							noWrap
							component="a"
							href="/"
							sx={{
								mr: 0,
								display: { xs: 'flex', sm: 'none' },
								flexGrow: 1,
								fontWeight: 700,
								color: 'inherit',
								textDecoration: 'none',
							}}
						>
						</Typography>
						<Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
							{pages.map((page, index) => (
								<Button
									key={page}
									href={pageLinks[index]}
									onClick={() => { 
										handleCloseNavMenu(); 
										logLinkOnClick(pageLinks[index].slice(1))();
									}}
									sx={{ my: 2, color: 'inherit', display: 'block'}}
								>
									{page}
								</Button>
							))}
						</Box>

						<Box sx={{ flexGrow: 0 }}>
							{ userdata["name"] || userdata["email"] || userdata["picture_url"] ?
							<React.Fragment><Tooltip title="Open settings">
								<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
									<Avatar alt={userdata["name"]} src={userdata["picture_url"]} />
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								{ userdata["name"] && <MenuItem disabled>{userdata["name"]}</MenuItem> }
								{ userdata["email"] && <MenuItem disabled>{userdata["email"]}</MenuItem> }
								<Divider />
								{settings.map((setting, index) => (
									<MenuItem key={setting} onClick={handleSettingsSelection(setting, settingsActions[index])}>
										<Typography textalign="center">{setting} </Typography>
									</MenuItem>
								))}
							</Menu></React.Fragment> :
							<Button
							onClick={logLinkOnClick("login")}
							href={`/login?redirect=${encodeURIComponent(location.pathname)}`}
							sx={{ my: 2, color: 'inherit', display: 'block' }}>
								{LOGIN_TITLE}
							</Button> }
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		</ThemeProvider>
	)
}