import React, { forwardRef } from "react";
import TextPage from "../pages/TextPage";
import { ERROR_TEXT } from "../utils/Constants";
import { report } from "../utils/AnalyticsUtils";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        this.setState({hasError: true});
        report(this.props.children?.type?.name, error, info);
    }

    render() {
        if (this.state.hasError) {
            return <TextPage text={ERROR_TEXT} subtext={<p>Please <a href="/support/feedback"><strong>submit an issue</strong></a> and describe your situation to help us resolve the issue.</p>}/>
        }
        return this.props.children;
    }
}

export function withErrorBoundary(Component) {
    //eslint-disable-next-line react/display-name
    return forwardRef((props, ref) => {
        return <ErrorBoundary>
            <Component ref={ref} {...props} />
        </ErrorBoundary>;
    });
}